import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Patient } from '../../patient-management/models/patient.interface';
import { FormGroup, FormControl, FormArray, Form, RequiredValidator, Validators } from '@angular/forms';
import { ControlledSubstanceLogsService } from '../../configuration-management/services/controlled-substance-logs.service';
import { ControlledSubstanceLog } from '../../configuration-management/models/controlled-substance-logs.models';
import { LineItem } from '../../line-item-entry/models/line-item.interface';
import { Client } from 'src/app/client-management/models/client.interface';
import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { RequestService } from 'src/app/requests-results/services/request.service';
import { AccountService } from 'src/app/configuration-management/services/account.service';
import {Branch} from "../../configuration-management/models/branch.interface";
import {WhiteboardLocations} from "../../whiteboard/models/whiteboard.interface";
import {BranchService} from "../../configuration-management/services/branch.service";
import {LocationService} from "../../configuration-management/services/location.service";
import {LocationSummary} from "../../configuration-management/models/locations.interface";


export interface ControlledSubstanceLogDialogData {
    lineItem: LineItem;
    patientId: string;
    patient: Patient;
    client: Client;
}

@Component({
    selector: 'app-controlled-substance-log-dialog',
    templateUrl: './controlled-substance-log.dialog.html',
    styleUrls: ['./controlled-substance-log.dialog.scss']
})
export class ControlledSubstanceLogDialogComponent implements OnInit {
    selectedClient = new Client();
    selectedItem = new LineItem();
    selectedPatient = new Patient();
    selectedBranch = new Branch();
    users: UserAccount[];
    locations: LocationSummary[];

    controlledSubstanceLogForm = new FormGroup({
        medicationSupplyId: new FormControl('', Validators.required),
        requestedForUserId: new FormControl('', Validators.required),
        wastage: new FormControl(''),
        balanceOnHand: new FormControl(''),
        bottleId: new FormControl('', Validators.required),
        date: new FormControl(new Date(), Validators.required),
        text: new FormControl('', Validators.required),
        patientId: new FormControl('', Validators.required),
        locationId: new FormControl('', Validators.required),
    });

    constructor(
        public dialogRef: MatDialogRef<ControlledSubstanceLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ControlledSubstanceLogDialogData,
        public snackBar: MatSnackBar,
        public controlledSubstanceLogsService: ControlledSubstanceLogsService,
        public requestService: RequestService,
        public accountService: AccountService,
        public locationService: LocationService,
    ) { }






    ngOnInit() {


        if (!this.data) {
            this.controlledSubstanceLogForm.controls.requestedForUserId.setValue(this.accountService.currentUserValue.id);

        } else if(this.data.lineItem != null) {
            this.controlledSubstanceLogForm = new FormGroup({
                medicationSupplyId: new FormControl(this.data.lineItem.itemId, Validators.required),
                requestedForUserId: new FormControl(this.data.lineItem.doctor.id, Validators.required),
                wastage: new FormControl(''),
                bottleId: new FormControl('', Validators.required),
                balanceOnHand: new FormControl(''),
                text: new FormControl(this.data.lineItem.quantity, Validators.required),
                date: new FormControl(this.data.lineItem.dateOfWork, Validators.required),
                patientId: new FormControl(this.data.patientId, Validators.required),
                locationId: new FormControl(this.data.lineItem.location.id, Validators.required),
            });

            this.selectedClient = this.data.client;
            this.selectedPatient = this.data.patient;

        } else {
            this.controlledSubstanceLogForm.controls.requestedForUserId.setValue(this.accountService.currentUserValue.id);
        }
        this.locationService.getInventoryLocations().then(res => this.locations = res);

        this.getUsers();
    }
    setLineItem(lineItem: LineItem) {
        this.controlledSubstanceLogForm.controls.medicationSupplyId.setValue(lineItem.id);
    }

    setPatient(event: Patient) {
        this.selectedPatient = event;
        this.selectedClient = { id: event.clientId, name: event.clientName } as Client;
        this.controlledSubstanceLogForm.controls.patientId.setValue(this.selectedPatient.id);

    }
    // Set Patient & Client
    setClient(event: Client) {
        this.selectedClient = new Client();
        this.selectedPatient = new Patient();

        setTimeout(() => {
            this.selectedClient = event;

        }, 100);
    }



    modelChanged() {

    }


    getUsers() {
        this.accountService.getUserAccountsList().subscribe(res => {
            this.users = res.accounts;
        });
    }
    submit() {
        const model = this.controlledSubstanceLogForm.value as ControlledSubstanceLog;
        this.controlledSubstanceLogsService.create(model, model.patientId).subscribe(res => {
            this.snackBar.open('Record has been created', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(res);
        });
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
