import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from '../../../configuration-management/services/user.service';
import {SpeciesService} from '../../../configuration-management/services/species.service';
import {Species} from '../../../configuration-management/models/species.interface';
import {PreferenceType} from '../../../configuration-management/models/user.interface';

@Component({
  selector: 'app-species-preference-filter',
  templateUrl: './species-preference-filter.component.html',
  styleUrls: ['./species-preference-filter.component.css']
})
export class SpeciesPreferenceFilterComponent implements OnInit {

  availableSpecies: Species[] = [];
  storedSpecies: Species[] = [];
  selectedSpecies: Species[] = [];

  @Output() updatedPreference = new EventEmitter();

  constructor(
    public userService: UserService,
    public speciesService: SpeciesService,
  ) { }

  ngOnInit() {
    this.speciesService.index().subscribe(allSpecies => {
      this.availableSpecies = allSpecies;
      this.userService.getSpeciesPreferences().subscribe(res => {
        this.selectedSpecies = res.species.map(item => this.availableSpecies.find(species => species.id === item.id));
        this.storedSpecies = this.selectedSpecies;
      });
    });
  }

  updatePreference() {
    this.userService.setSpeciesPreference(this.selectedSpecies.map(s => s.id)).subscribe(res => {
      this.storedSpecies = res.species.map(item => this.availableSpecies.find(species => species.id === item.id));
      this.updatedPreference.emit();
    });
  }
}
