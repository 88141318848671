import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LabSummary } from '../models/labs.interface';
import { LabResult } from 'src/app/whiteboard/models/order.interface';

@Injectable({
  providedIn: 'root',
})
export class LabResultService extends BaseService {

  public getItem(itemId: string): Observable<LabResult> {
    const route = `${this.apiUrl}/LabResult/${itemId}`;

    return this.http.get<LabResult>(route, this.headers()).pipe(map(response => {
      console.log("Lab Result", response)
      return response;
    }));
  }


  public index(): Observable<LabResult[]> {
    const route = `${this.apiUrl}/LabResult`;

    return this.http.get<LabResult[]>(route, this.headers()).pipe(map(response => {
      console.log("Lab Result new", response)
      return response;
    }));
  }

  public create(model: LabResult): Observable<LabResult> {
    const route = `${this.apiUrl}/LabResult/Create`;
    const body = JSON.stringify(model);


    return this.http.post<LabResult>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: LabResult): Observable<LabResult> {
    const route = `${this.apiUrl}/LabResult/Update`;
    const body = JSON.stringify(model);


    return this.http.post<LabResult>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
