import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { RequestDialogComponent } from "../request.dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material";
import { RequestService } from "../../../services/request.service";
import { UserAccount} from "../../../../configuration-management/models/user.interface";
import { AccountService } from "../../../../configuration-management/services/account.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Request } from '../../../models/request.interface';

@Component({
  selector: 'app-request-message-dialog',
  templateUrl: './message.dialog.html',
  styleUrls: ['./message.dialog.scss']
})
export class MessageDialogComponent implements OnInit {

  @ViewChild('table', { static: false }) table: MatTable<UserAccount>;
  displayedColumns: string[] = ['doctor', 'clear'];

  allDoctors: UserAccount[];
  doctorList: string[] = [];
  searchCtrl = null;

  dataSource = new MatTableDataSource([]);

  constructor(
    private accountService: AccountService,
    public dialogRef: MatDialogRef<RequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Request,
    public snackBar: MatSnackBar,
    public requestService: RequestService
  ) {
  }

  ngOnInit() {
    this.getDoctors();
  }

  getDoctors() {
    this.accountService.getDoctors().then(res => {
      this.allDoctors = res;
    });
    this.dataSource.data = this.data.doctorList;
  }

  setDoctor(userName: string, row: number) {
    this.doctorList[row] = this.allDoctors.find(x => x.name === userName).id;
  }

  removeItem(doctor: UserAccount) {
    this.dataSource.data = this.dataSource.data.filter(x => x !== doctor).sort(x => x.position);

    // tslint:disable-next-line: no-shadowed-variable
    this.dataSource.data.forEach((item, index) => {
      item.position = index + 1;
    });

    setTimeout(() => {

    }, 2000);
  }

  addRow(index: number) {
    if (index == this.dataSource.data.length - 1) {
      let data = this.dataSource.data as UserAccount[];
      const emptyUser = new UserAccount();
      data.push(emptyUser);
      this.dataSource.data = data;
      this.table.renderRows();
    }
  }

  submit() {
    this.dataSource.data.pop()
    this.dialogRef.close(this.doctorList);
  }
}
