import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Overview, Reminder } from '../models/overview.iterface';
import { OverviewReminder, OverviewReminderItem } from '../../configuration-management/models/overview-reminder.interface';
import { LineItem } from '../../line-item-entry/models/line-item.interface';
import { PrintSend } from 'src/app/shared/send-print-button/send-print.model';

@Injectable({
    providedIn: 'root',
})
export class EQProtectService extends BaseService {

    public getOverview(patientId: string): Observable<Overview> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/${patientId}`;

        return this.http.get<Overview>(route , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public printSendOverview(model: PrintSend, reminders: Reminder[]): any {

        const route = `${this.apiUrl}/EQProtect/${model.patientId}/PrintOrSend`;

        const body = JSON.stringify({ printSend: model, reminders });
        return this.http.post(route, body, this.fileHeaders()).pipe(map(res => {
          return new Blob([res], { type: 'application/pdf' });
        }));
    }

    public updateVaccines(patientId: string): Observable<any> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/Patient/${patientId}/UpdateVaccines`;

        return this.http.post<any>(route, null , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    // This is for use in configuration management and is only accessible by system admins
    public index(): Observable<OverviewReminder[]> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect`;

        return this.http.get<OverviewReminder[]>(route , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    // This is a list of only overview reminders in eqprotect accessible by all relevant users
    public getEQProtectList(): Observable<OverviewReminder[]> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/EQProtectList`;

        return this.http.get<OverviewReminder[]>(route , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    // This is a list of all overview reminders accessible by all roles
    public getOverviewReminderList(): Observable<OverviewReminder[]> {
        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/GetOverviewReminders`;
        return this.http.get<OverviewReminder[]>(route , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public create(model: OverviewReminder): Observable<OverviewReminder> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect`;
        const body = JSON.stringify(model);

        return this.http.post<OverviewReminder>(route , body, this.headers()).pipe(map(response => {
            return response;
        }));
    }
    public update(model: OverviewReminder): Observable<OverviewReminder> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/${model.id}`;
        const body = JSON.stringify(model);
        return this.http.post<OverviewReminder>(route , body, this.headers()).pipe(map(response => {
            return response;
        }));
    }
    public disable(id: string): Observable<OverviewReminder> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/${id}/Disable`;

        return this.http.post<OverviewReminder>(route , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public addItem(overviewReminderId: string, model: LineItem ): Observable<OverviewReminder> {

        if (!this.isConnected) {
            return;
        }

        const overviewItem = new OverviewReminderItem();
        if (model.lineItemCategory === 'MedicationsAndSupplies') {
            overviewItem.medicationsSuppliesId = model.id;
        } else if (model.lineItemCategory === 'Group') {
            overviewItem.groupId = model.id;
        } else if (model.lineItemCategory === 'ServiceCode') {
            overviewItem.serviceCodeId = model.id;
        } else if (model.lineItemCategory === 'Labs') {
            overviewItem.labId = model.id;
        }


        const route = `${this.apiUrl}/EQProtect/${overviewReminderId}/AddItem`;
        const body = JSON.stringify(overviewItem);
        return this.http.post<OverviewReminder>(route , body, this.headers()).pipe(map(response => {
            return response;
        }));
    }
    public disableItem(overviewReminderId: string, overviewReminderItemId: string): Observable<OverviewReminder[]> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/${overviewReminderId}/DisableItem/${overviewReminderItemId}`;

        return this.http.post<OverviewReminder[]>(route, null , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public enrollPatient(patientId: string ): Observable<any> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/${patientId}/Enroll`;
        return this.http.post<any>(route, null , this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public enrollPatients(patientIds: string[] ): Observable<any> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/EnrollMany`;
        return this.http.post<any>(route, patientIds , this.headers()).pipe(map(response => {
            return response;
        }));
    }


    public updateReminder(reminderId: string, treatmentDate: Date, renewalDate: Date): Observable<any> {

        if (!this.isConnected) {
            return;
        }

        const route = `${this.apiUrl}/EQProtect/Reminder/${reminderId}`;
        const body = JSON.stringify({treatmentDate, renewalDate});
        return this.http.post<any>(route, body , this.headers()).pipe(map(response => {
            return response;
        }));
    }


}
