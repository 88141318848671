import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmationDialog {
    header: string;
    body: string;
    type: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation.dialog.html',
  styleUrls: ['./confirmation.dialog.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  emailOption: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
    if (this.data.emailOption) {
      this.emailOption = this.data.emailOption;
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
