import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dbContext } from '../../offline/offline-db.service';
import { Client } from '../../../client-management/models/client.interface';
import { Router } from '@angular/router';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { PatientViewDialog } from '../patient-view-dialog/patient-view.dialog';

@Component({
  selector: 'app-client-view-dialog',
  templateUrl: './client-view.dialog.html',
  styleUrls: ['./client-view.dialog.scss']
})
export class ClientViewDialog implements OnInit {

  client: Client;

  constructor(
    public dialogRef: MatDialogRef<ClientViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public router: Router,
    public dialog: MatDialog
  ) { }


  ngOnInit() {

    this.getClientById();
  }

  getClientById() {


    dbContext.clientSearch.where('id').equals(this.data).first().then(x => {
      this.client = x;
      this.client.patients = this.client.patients.sort((a, b) => (a.patientStatus > b.patientStatus ? 1 : -1));
    });
  }

  route() {
    this.router.navigateByUrl(`/client-management/client-record/${this.data}`);
    this.dialogRef.close();
  }

  viewPatient(patient: Patient) {

    const dialogRef = this.dialog.open(PatientViewDialog, {
      width: '100vw',
      height: '99vh',
      data: patient.id
  });
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
