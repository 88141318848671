import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/patient-management/services/patient.service';
import FileSaver from 'file-saver';
import { SendMedicalHistory } from 'src/app/patient-management/models/patient.interface';
import * as _ from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';



@Component({
    selector: 'app-send-to-other-dialog',
    templateUrl: './send-to-other.dialog.html',
    styleUrls: ['./send-to-other.dialog.scss'],
})
export class SendToOtherDialogComponent implements OnInit {

    otherEmail = '';
  

  
    constructor(

        public dialogRef: MatDialogRef<SendToOtherDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        public snackBar: MatSnackBar,
        public patientService: PatientService

    ) {

    }

    ngOnInit() {

    }

    send() {

        this.dialogRef.close(this.otherEmail);

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
