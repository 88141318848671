import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WhiteboardService } from 'src/app/whiteboard/services/whiteboard.service';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { Whiteboard } from 'src/app/whiteboard/models/whiteboard.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { AccountService } from 'src/app/configuration-management/services/account.service';

@Component({
  selector: 'app-whiteboard-prompt',
  templateUrl: './whiteboard-prompt.dialog.html',
  styleUrls: ['./whiteboard-prompt.dialog.scss']
})
export class WhiteboardPromptDialogComponent implements OnInit {

  canSubmit = false;
  dateAdmitted: Date;
  patient: Patient;
  doctors: UserAccount[];

  whiteboardForm = new FormGroup({
    doctor: new FormControl('', Validators.required),
    intern: new FormControl('', Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<WhiteboardPromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    private whiteboardService: WhiteboardService,
    public accountService: AccountService,
  ) { }


  ngOnInit() {
    this.patient = this.data.patient;
    this.getDoctors();
  }

  getDoctors() {
    this.accountService.getDoctors().then(res => {
      this.doctors = res;
    });
  }

  validate(isValid) {
       this.canSubmit = isValid;
  }

  submitWhiteboardForm() {
    let whiteboard = this.whiteboardForm.value as Whiteboard;
    whiteboard.dateAdmitted = new Date();

    this.whiteboardService.createWhiteboard(whiteboard, this.patient.id).subscribe(
      whiteboardId => {
        this.snackBar.open('Patient Added to Whiteboard', 'Success', {
          duration: 2000,
        });
        this.dialogRef.close(whiteboardId);
      },
      error => {
        this.snackBar.open('Patient Could Not Be Added to Whiteboard', 'Error', {
          duration: 2000,
        });
      }
    );
  }

  submitDischargeForm() {
    const dateDismissed = new Date();
    this.whiteboardService.dischargePatient(dateDismissed, this.patient.currentWhiteboardId).subscribe(
      response => {
        this.snackBar.open('Patient Discharged', 'Success', {
          duration: 2000,
        });
        this.dialogRef.close();
      },
      error => {
        this.snackBar.open('Patient Could Not Be Discharged', 'Error', {
          duration: 2000,
        });
      }
    );
  }



  onNoClick() {
    this.dialogRef.close();
  }
}
