import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { dbContext } from '../offline/offline-db.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {

  public searchItemsNoGroups(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchLineItemsNoGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public async getLineItems() {
      return dbContext.enabledLineItems;
  }

  public searchFluids(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchFluids/${encodeURIComponent(searchString)}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchVitalGroups(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchVitalGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchVitals(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchVitals/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchDailyCare(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchDailyCare/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchLabResults(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchLabResults/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public searchLabs(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchLabs/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchServiceCodes(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchServiceCodes/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchMedicationsAndSupplies(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchMedicationsAndSupplies/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchGroups(searchString: string): Observable<any[]> {

    const body = searchString;
    const route = `${this.apiUrl}/Search/SearchGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


}
