import { Contact, ClientContacts } from './contact.interface';
import { ClientStatus } from 'src/app/helpers/enums';
import { Tag } from 'src/app/shared/tags/tag.model';
import { Note } from 'src/app/shared/notes/note.model';
import { Patient, PatientSummary } from '../../patient-management/models/patient.interface';
import { Statement } from 'src/app/list-communications/models/statement.interface';
import { Invoice } from 'src/app/line-item-entry/models/invoice.interface';
import { type } from 'os';



export class Page {
  size = 10;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  hasFilter = false;
  clientFilter = '';
  patientFilter = '';
  addressFilter = '';
  status = '';
  phoneFilter = '';
  patientIdFilter = '';
  clientIdFilter = '';
  emailFilter = '';

  branchId: string = null;
}

export class ClientSummaryListViewModel {
  clients: Array<Client>;
  page = new Page();
  isOffline: boolean;
}

export interface ClientSummarySync {
  clients: Client[];
  dateSynced: string;
}

export class Client {
  id = '';
  visible = false;
  clientStatus: string;
  firstName: string;
  lastName: string;
  name: string;
  clientNumber: string;
  primaryPhone: string;
  secondaryPhone: string;
  email: string;
  physicalStreetAddress: string;
  physicalStreetAddress2: string;
  physicalCity: string;
  physicalState: string;
  branchId: string;
  physicalZip: string;
  sameAsBilling: string;
  billingStreetAddress: string;
  billingStreetAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingTypes: string[];
  canMail: boolean;
  canEMail: boolean;
  clientId: string;
  canOther: boolean;
  physicalAddress: string;
  billingAddress: string;
  createdDate: Date;
  notes: CreateClientNote[];
  contacts: Contact[];
  phoneNumbers: PhoneNumber[];
  patients: Patient[];
  tags: Tag[];
  privateNotes: Note[];
  normalNotes: Note[];
  branchName: string;
  accountBalance: number;
  suppressInterest: boolean;
  hasTransfers: boolean;
  hasAccount: boolean;
}


export interface PhoneNumber {
  number: string;
  description: string;
  id: string;
}

export interface ClientPatient {
  name: string;
  id: string;
}


export interface ClientDialogData {
  id: string;
}

export class CreateClientNote {
  text: string;
  isImportant: boolean;
  isPrivate: boolean;
  visible = false;
}

export interface ClientNote {
  id: string;
  text: string;
  isImportant: boolean;
  isPrivate: boolean;
  clientId: string;
}

export interface ClientNoteList {
  notes: ClientNote[];
}

export interface ClientFinancials {
  statements: StatementSummaryViewModel[];
  unfinishedInvoices: Invoice[];
}

export class StatementSummaryViewModel {
  monthAndYear: Date;
  initialBalanceDate: Date;
  initialBalance: number;
  runningTotal: number;
  accountBalance: number;
  financialActivities: FinancialActivity[];
}

export class FinancialActivity {
  date: Date;
  type: string;
  patient: PatientFinancialViewModel;
  title: string;
  paymentId: string;
  invoiceId: string;
  amount: number;
  currentTotal: number;

  constructor(model) {
    this.date = model.date;
    this.type = model.type;
    this.currentTotal = model.currentTotal;
  }
}

export class PatientFinancialViewModel {
  id: string;
  name: string;
  patientNumber: string;
}


export class BillingType {
  status: string;
  name: string;
  backgroundColor: string;
}


export const BILLINGTYPES = [
  { name: 'Billing Client', status: 'BillingClient', backgroundColor: 'white' },
  { name: 'No Service', status: 'NoService', backgroundColor: '#ec2131' },
  { name: 'Card on File', status: 'CardOnFile', backgroundColor: '#02a00c' },
  { name: 'Cash Only', status: 'CashOnly', backgroundColor: '#02a00c' },
  { name: 'Pay at time of service', status: 'TimeOfService', backgroundColor: '#f08300' }
];
