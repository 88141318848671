import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Patient } from '../../patient-management/models/patient.interface';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { LogsService } from '../../configuration-management/services/logs.service';
import { LogPage, SystemLog } from '../../configuration-management/models/logs.models';
import { Observable } from 'rxjs/internal/Observable';



@Component({
    selector: 'app-item-log-dialog',
    templateUrl: './item-log.dialog.html',
    styleUrls: ['./item-log.dialog.scss']
})
export class ItemLogDialogComponent implements OnInit {

    systemLogs: SystemLog[];
   // tslint:disable-next-line: max-line-length
   displayedColumns = ['userName', 'logType', 'logAction', 'message','model', 'dateCreated'];


    constructor(
        public dialogRef: MatDialogRef<ItemLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LogPage,
        public snackBar: MatSnackBar,
        public logService: LogsService

    ) { }


    ngOnInit() {
        this.logService.itemLogs(this.data).subscribe(x => {
            this.systemLogs = x;
        });
    }

   

    onNoClick() {
        this.dialogRef.close();
    }
}
