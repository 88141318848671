import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { PatientService } from 'src/app/patient-management/services/patient.service';
import { Client } from 'src/app/client-management/models/client.interface';
import { debounceTime } from 'rxjs/operators';


@Component({
    selector: 'app-search-patients',
    templateUrl: './search-patients.component.html',
    styleUrls: ['./search-patients.component.scss']
})
export class SearchPatientsComponent implements OnInit, OnChanges {

    @Input() patient: Patient;
    @Input() client: Client;
    @Output() selectedPatient = new EventEmitter<Patient>();
    @Input() disabled: boolean;

    patientSearch = new FormControl('');
    patientResults: Patient[] = [];

    constructor(
        public patientService: PatientService
    ) { }

    ngOnInit() {
        if (this.patient && this.patient.id !== '') {
            this.patientSearch = new FormControl(this.patient.name);
        }
        if (this.disabled) {
            this.patientSearch.disable();
        }

        this.patientSearch.valueChanges
        .pipe(
          debounceTime(300)
        )
        .subscribe(data => {
          this.searchPatients(this.patientSearch.value);
        });
        if (this.client != null && this.client.id !== '') {
          this.getPatientsByClientId();
        }
    }

    ngOnChanges() {
        if (this.client != null && this.client.id !== '' && this.patient != null) {
            this.getPatientsByClientId();
        }
    }

    searchPatients(patientSearch: string) {
      console.log(this.patientResults)
      if (this.client != null) {
        patientSearch == '' ? this.getPatientsByClientId() : null;
        let newPatientList = [];
        this.patientResults.forEach(patient => {
          patient.name.toLowerCase().includes(patientSearch.toLowerCase()) ? newPatientList.push(patient) : null;
        })
        this.patientResults = newPatientList;
      } else {
        this.patientService.searchPatients(patientSearch).subscribe(
          response => {
            if (response.length > 0) {
              this.patientResults = response;
            }
          }
        );
      }
    }

    getPatientsByClientId() {
        this.patientService.getPatientSummaryByClientId(this.client.id).subscribe(
            response => {
                this.patientResults = response;
        });
    }

    selectPatient(patient: Patient) {
        this.patient = patient;
        this.patientSearch = new FormControl(this.patient.name);
        this.selectedPatient.emit(patient);
    }
}
