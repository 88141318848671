import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { Branch } from 'src/app/configuration-management/models/branch.interface';
import {
  Order,
  WhiteboardOrders,
  ActiveHours,
  WhiteboardIndexOrder,
  WhiteboardIndexActiveHours
} from './order.interface';
import { Treatment } from './treatment.interface';
import {LocationSummary, WhiteboardLocationFilter} from '../../configuration-management/models/locations.interface';
import { Stall } from 'src/app/configuration-management/models/stall.interface';
import { Invoice } from '../../line-item-entry/models/invoice.interface';
import { Hospitalization } from 'src/app/configuration-management/models/hospitalization.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';

export class Whiteboard {
  id: string;
  patientId: string;
  clientId: string;
  dateAdmitted: Date;
  dateDismissed: Date;
  whiteboardConfirmedDate: Date;
  patient: Patient;
  doctor: UserAccount;
  resident: UserAccount;
  intern: UserAccount;
  primaryCommunicator: string = PrimaryCommunicator.resident;
  treatments: Treatment[];
  stall: Stall;
  branch = new Branch();
  location: LocationSummary;
  hospitalization = new Hospitalization();
  whiteboardTreatments: Treatment[];
  whiteboardOrders: WhiteboardOrders;
  currentInvoice = new Invoice();
  doctorCode: string;
  residentCode: string;
  internCode: string;
  // orders: Orders;
  notes: string;
}

export class WhiteboardIndex {
  id: string;
  patientId: string;
  clientId: string;
  dateAdmitted: Date;
  dateDismissed: Date;
  whiteboardConfirmedDate: Date;
  patient: Patient;
  doctor: UserAccount;
  resident: UserAccount;
  intern: UserAccount;
  primaryCommunicator: string;
  treatments: Treatment[];
  stall: Stall;
  branch = new Branch();
  location: LocationSummary;
  hospitalization = new Hospitalization();
  whiteboardTreatments: Treatment[];
  whiteboardOrders: WhiteboardIndexOrder[];
  activeHours: WhiteboardIndexActiveHours[] = [];
  currentInvoice = new Invoice();
  doctorCode: string;
  residentCode: string;
  internCode: string;
  // orders: Orders;
  notes: string;
}

export class WhiteboardFilterSources {
  locations: WhiteboardLocationFilter[];
  doctors: UserAccount[];
}

export class WhiteboardFilters {
  date: Date;
  doctorFilterId: string;
  locationFilterId: string;
}



// Dummy Models, might be modified as real models become clearer
export interface WhiteboardPage {
  date: Date;
  doctorFilter: WhiteboardUser;
  locationFilter: string; // enum?
  columns: string[];
  patients: PatientWhiteboardModel[];
}

export interface PatientWhiteboardModel {
  id: string;
  name: string;
  patientNumber: number;
  doctor: WhiteboardUser;
  intern: WhiteboardUser;
  clientName: string;
  clientNumber: number;
  location: HospitalLocationModel;
  whiteboardTreatments: WhiteboardTreatment[];
}

export interface WhiteboardUser {
  id: string;
  name: string;
  // role ?
}

export interface HospitalLocationModel {
  id: string;
  name: string;
  description: string;
  site: string;
}

export interface WhiteboardTreatment {
  startTime: string;
  treatments: Treatment[];
}

export const HOUR_COLUMNS = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

export const HOUR_SHIFT_COLUMNS = [
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',


];


export class WhiteboardLocations {
  locations: WhiteboardLocation[];
  NeedPlacement: Whiteboard[];
}

export class WhiteboardLocation {
  stalls: WhiteboardStall[];
  name: string;
  stallStatus: string;
  id: string;
  branchId: string;
  branchName: string;
}

export class WhiteboardStall {
  whiteboards: Whiteboard[];
  id: string;
  stallStatus: string;
  number: string;
}

export class ChangeWBDoctors {
  whiteboardIds: string[];
  doctorId: string;
  doctorCode: string;
  residentId: string;
  residentCode: string;
  internId: string;
  internCode: string;
  primaryCommunicator: string;
}

export class FeedCard {

  patient: Patient;
  dailyCare: ActiveHours[] = [];
  active = false;
  stall: Stall;
}

export enum PrimaryCommunicator{
  doctor = 'Doctor',
  resident = 'Resident',
  intern = 'Intern'
}
