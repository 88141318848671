export class LogPage {
    size = 10;
    totalElements = 0;
    totalPages = 0;
    pageNumber = 0;
    hasFilter = false;
    filter = '';
    userId = '';
    searchFilter = '';
    logAction = '';
    logType = 'User';
    itemId = '';
}

export class SystemLog {
    userName: string;
    userId: string;
    message: string;
    logAction: string;
    logType: string;
    createdDate: string;
}

export class LogViewModel {
    page = new LogPage();
    systemLogs: SystemLog[];
}
export enum RevisionAction {
  add,
  update,
  delete
}
export class LineItemRevisionModel {
  id: string
  revisionAction: RevisionAction
  invoiceId: string
  doctorName: string
  asstDoctorName: string
  quantity: number
  totalPrice: number
  dateOfWork: Date
  discount: string
  price: string
  comments: string
  location: string
  lineItemCode: string
  isRevision: boolean
  isOriginal: boolean
  incorrectQuantity: boolean
  missingLineItem: boolean
  wrongLineItem: boolean
  incorrectTravelFee: boolean
  wrongDoctor: boolean
}
