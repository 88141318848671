import { Note } from 'src/app/shared/notes/note.model';
import { Tag } from 'src/app/shared/tags/tag.model';
import { Whiteboard } from 'src/app/whiteboard/models/whiteboard.interface';
import { Overview } from './overview.iterface';
import { ReproductiveLog } from 'src/app/shared/patient-reproductive-log-dialog/patient-reproductive-log.model';
import { Client } from 'src/app/client-management/models/client.interface';
import { OverviewReminder } from '../../configuration-management/models/overview-reminder.interface';

export interface PatientSummary {
    name: string;
    id: string;
    location: string;
    notes: string;
}

export interface EditPatient {
    name: string;
    id: string;
    location: string;
    notes: string;
}

export interface PatientDialogData {
    id: string;
}

export class Patient {
    name: string;
    hasEQProtect: boolean;
    hideFromClient: boolean;
    almostMeetsEQProtect: boolean;
    patientNumber: string;
    id = '';
    ageYears: number;
    age: number;
    dateOfBirth: Date;
    species: string;
    patientStatus: string;
    profilePictureURL: string;
    microchip: string;
    isAdmitted: boolean;
    location: string;
    color: string;
    insuranceProvider: string;
    insurancePatientNumber: string;
    insurancePhoneNumber: string;
    breed: string;
    patientGender: string;
    patientSpecies: string;
    weight:string;
    // description: string;
    // admittedDate: Date;
    clientId: string;
    clientName: string;
    clientNumber: string;
    doctorName: string;
    currentWhiteboardId: string;
    notes: Note[] = [];
    tags: Tag[];
    client: Client;
    medicalNotes: PatientMedicalRecord[];
    overviewReminders: OverviewReminder[];
}

export interface PatientList {
    patients: PatientWithOverviewSummary[];
    overviewReminders: Overview[];
}


export interface PatientWithOverviewSummary {
    patient: Patient;
    overview: Overview;
}

export class MedicalHistorySummary {
    patientReproductiveLogs: ReproductiveLog[] = [];
    patientVitals: any;
    patientLabResults: any;
    vitalHeaders: string[];
}


export class SendMedicalHistory {
    recipient: string;
    clientId: string;
    patientId: string;
    date = new Date();
    patient: Patient;
    historyItems: HistoryItem[];
}

export class HistoryItem {
    itemType: string;
    date: Date;
    id: string;
}


export class EditPatientMedicalRecord{
    patientMedicalRecord: PatientMedicalRecord;
    patient: Patient;
}

export class PatientMedicalRecordsPaginated{
  count:number;
  patientMedicalRecords: [PatientMedicalRecord];
}
export class PatientMedicalRecord {
    id: string;
    type: string;
    doctorName: string;
    doctorId: string;
    date: Date;
    createdDate: Date;
    endDate: Date;
    showToClient: boolean;
    selected: boolean;
  }
