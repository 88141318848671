import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrphanHeskaTests, Request } from '../../../models/request.interface';
import { RequestService } from '../../../services/request.service';
import { LabRequestResult } from '../../../../whiteboard/models/order.interface';
import { FormGroup, FormControl, Validators, FormArray, Form } from '@angular/forms';
import { FileUploadModel } from 'src/app/patient-management/patient-record/patient-picture-dialog/patient-picture.dialog';
import FileSaver from 'file-saver';
import { AccountService } from '../../../../configuration-management/services/account.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation.dialog';
import { PrintSend } from 'src/app/shared/send-print-button/send-print.model';
import { Branch } from 'src/app/configuration-management/models/branch.interface';
import { BranchService } from 'src/app/configuration-management/services/branch.service';
import { OrphanResultsDialogComponent } from '../orphan-results-dialog/orphan-results.dialog';
import { MessageDialogComponent } from "../../request-dialog/message-dialog/message.dialog";

@Component({
  selector: 'app-lab-results',
  templateUrl: './lab-results.dialog.html',
  styleUrls: ['./lab-results.dialog.scss']
})
export class LabResultsDialogComponent implements OnInit {

    labResults: LabRequestResult[];
    request: Request;
    model: any;
    formIsValid: boolean;
    branches: Branch[];
    orphanTests: OrphanHeskaTests;
    public files: Array<FileUploadModel> = [];
    resultsForm = new FormGroup({
        requestNotes: new FormControl(''),
        emailNotes: new FormControl(''),
        labResults: new FormArray([])
    });

    constructor(

        public dialogRef: MatDialogRef<LabResultsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Request,
        public snackBar: MatSnackBar,
        public requestService: RequestService,
        public accountService: AccountService,
        public dialog: MatDialog,
        public branchService: BranchService
    ) {

    }

    ngOnInit() {
      this.getBranches();
        if (this.data.lineItem.requestId != null) {
            this.getRequest();
        } else {
            this.model = this.data;
            this.getLabRequestResults();
        }
    }

    getBranches() {
      this.branchService.index().subscribe(x => {
        this.branches = x;
      });
    }

    isNumber(value: string | number): boolean
    {
      return ((value != null) &&
              (value !== '') &&
              !isNaN(Number(value.toString())));
    }

    setOrphanTests() {
        const dialogRef = this.dialog.open(OrphanResultsDialogComponent, {
            width: '1000px',
            height: '800px',
            data: {
                requestId: this.request.id
            }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              this.ngOnInit();
            }

          });
    }

    applyBranchChange(){
      this.requestService.updateRequestLocation(this.model.branch.id, this.data.id).subscribe(res => {
        this.snackBar.open('Request location has been updated', 'Success', {
          duration: 2000,
      });
      },
      error => {
        this.snackBar.open('Request location could not be updated.', 'Error', {
          duration: 2000,
      });
      });
    }

    getRequest() {
        this.requestService.getRequest(this.data.lineItem.requestId).subscribe(res => {

            this.model = res;
            this.getTreatmentRequestResults();
        });
    }

  updateEmailNotes(emailNotes: string) {
    this.requestService.updateEmailNotes(this.data.lineItem.requestId, emailNotes).subscribe(res => {
      this.request.emailNotes = emailNotes;
      this.modelChanged();
      this.snackBar.open('Email notes have been updated', 'Success', {
        duration: 2000,
      });

    },
      error => {
        this.snackBar.open('Email notes could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  getTreatmentRequestResults() {
    this.requestService.getLabRequestResults(this.model.lineItem.itemId,
      this.model.lineItem.id,
      this.model.id).subscribe(
        res => {
          this.labResults = res.labResults;
          this.request = res.request;
          this.updateFormInputs();
          this.request.requestType = "Lab";
        }
      );
  }

    getLabRequestResults() {
        this.requestService.getLabRequestResults(this.data.lineItem.itemId, this.data.lineItem.id, this.data.id).subscribe(
            res => {
                this.labResults = res.labResults;
                this.request = res.request;
                this.request.requestType = "Lab";
                this.updateFormInputs();
            }
        );
    }

    updateFormInputs() {
        const resultInputs = new FormArray([]);

        this.labResults.forEach(labResult => {
            if (labResult.type === 'List') {
                labResult.listValues = labResult.defaultValue.split(',');
            }

            resultInputs.push(new FormGroup({
                name: new FormControl(labResult.name),
                minValue: new FormControl(labResult.minValue),
                maxValue: new FormControl(labResult.maxValue),
                value: new FormControl(labResult.value, [
                    Validators.required,
                    Validators.min(labResult.minValue),
                    Validators.max(labResult.maxValue)
                ]),
                type: new FormControl(labResult.type),
                id: new FormControl(labResult.id),
                labResultId: new FormControl(labResult.labResultId),
                noAnswer: new FormControl(labResult.noAnswer)
            }));

            labResult.lineItemId = this.model.lineItem.id;
            labResult.patientId = this.model.lineItem.patient.id;

        });

        this.resultsForm = new FormGroup({
            requestNotes: new FormControl(this.request.requestNotes),
            emailNotes: new FormControl(this.request.emailNotes),
            labResults: resultInputs
        });

    }

    checkFormIsValid() {
        if (this.resultsForm.valid === true) {
            this.formIsValid = true;
        } else {
            var i = 0;
            this.labResults.forEach(x => {

                if (this.checkValid(i)) {
                    this.formIsValid = true;

                } else {
                    this.formIsValid = false;

                    return;
                }

                i++;
            });
        }

    }

    cancel() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: {
                header: 'Cancel Lab Request',
                body: `You are about to cancel a lab request for ${this.model.lineItem.name}.`,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.requestService.cancellRequest(this.request.id).subscribe(res => {

                    this.snackBar.open('Request has been cancelled', 'Success', {
                        duration: 2000,
                    });
                    this.dialogRef.close('reload');
                },
                    error => {
                        this.snackBar.open('Request could not be cancelled', 'Error', {
                            duration: 2000,
                        });
                    });

            } else {
                // cancel
                this.snackBar.open('Operation cancelled.', '', {
                    duration: 2000,
                });
            }
        });



    }

    checkValid(index): boolean {
        const control = this.resultsForm.get(['labResults', index]);

        return (control.valid) || (!control.valid && control.value.noAnswer === true);
    }

    modelChanged() {
        this.checkFormIsValid();
    }

    submit() {
        this.modelChanged();
        this.request.requestNotes = this.resultsForm.get('requestNotes').value;
        this.request.emailNotes = this.resultsForm.get('emailNotes').value;

        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '600px',
          data: {
            doctorList: [this.model.requestedUser]
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result !== undefined) {
            this.request.additionalContacts = result
            this.updateFormInputs()

            if (this.data.isCompleted) {
              this.requestService
                .updateLabRequestResults(this.labResults, this.request, this.data.lineItem.itemId, this.data.lineItem.id).subscribe(res => {
                  this.snackBar.open('Lab Results Successfully updated', 'Success', {
                    duration: 2000,
                  });
                  this.dialogRef.close('Success');
                },
                error => {
                  this.snackBar.open('Lab Results were not updated', 'Error', {
                    duration: 2000,
                  });
                });
            } else {
              this.request.isCompleted = true;
              this.requestService
                .setLabRequestResults(this.labResults, this.request, this.data.lineItem.itemId, this.data.lineItem.id).subscribe(res => {
                this.snackBar.open('Lab Results have been added', 'Success', {
                  duration: 2000,
                });
                this.dialogRef.close('Success');
              }, error => {
                this.snackBar.open('Lab Results were not added', 'Error', {
                  duration: 2000,
                });
              });
            }
          }
        });
    }

    saveProgress() {
        this.modelChanged();
        this.request.requestNotes = this.resultsForm.get('requestNotes').value;
        this.request.emailNotes = this.resultsForm.get('emailNotes').value;
        this.request.isCompleted = false;

        if (this.labResults.length > 0 && this.labResults[0].id !== null) {
            this.requestService
                .updateLabRequestResults(this.labResults, this.request, this.data.lineItem.itemId, this.data.lineItem.id).subscribe(res => {
                    this.snackBar.open('Lab Results Successfully updated', 'Success', {
                        duration: 2000,
                    });
                    this.dialogRef.close('Success');
                },
                    error => {
                        this.snackBar.open('Lab Results were not updated', 'Error', {
                            duration: 2000,
                        });
                    });
        } else {

            this.requestService
                .setLabRequestResults(this.labResults, this.request, this.data.lineItem.itemId, this.data.lineItem.id).subscribe(res => {
                    this.snackBar.open('Lab Results have been added', 'Success', {
                        duration: 2000,
                    });
                    this.dialogRef.close('Success');
                }, error => {
                    this.snackBar.open('Lab Results were not added', 'Error', {
                        duration: 2000,
                    });
                });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getFilesToUpload() {
        const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.files.push({
                    data: file, state: 'in',
                    inProgress: false, progress: 0, canRetry: false, canCancel: true
                });
            }
            this.uploadFiles();
        };
        fileUpload.click();
    }

    cancelFile(file: FileUploadModel) {
        file.sub.unsubscribe();
        this.removeFileFromArray(file);
    }

    retryFile(file: FileUploadModel) {
        file.canRetry = false;
    }

    private uploadFiles() {
        const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
        fileUpload.value = '';

        if (this.files.length >= 1) {

            var files = this.files.map(element => element.data);

            this.requestService.uploadRequestFile(this.data.id, files).subscribe(x => {
                this.files = [];
                this.snackBar.open('Patient file successfully uploaded', 'Success', {
                    duration: 2000,
                });
            }, error => {
                this.snackBar.open('Patient file was not uploaded', 'Error', {
                    duration: 2000,
                });
                this.files = [];
            });
        }
    }

    private removeFileFromArray(file: FileUploadModel) {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
        }
    }

    // Download Template
    downloadTemplate() {
        this.requestService.getLabRequestFile(this.data.id).subscribe(blob => {
            FileSaver.saveAs(blob, this.data.patientName + ' ' + this.model.lineItem.name + '.pdf');
        });
    }

    printOrSend(printSend: PrintSend) {

        printSend = JSON.parse(JSON.stringify(printSend));
        printSend.clientId = this.request.clientId;
        printSend.patientId = this.request.patientId;
        printSend.recordId = this.request.id;
        printSend.recordType = "Lab";




        this.requestService.sendLabRequestResults(this.request.patientId, printSend).subscribe(blob => {


            this.snackBar.open('Print or Send Complete', 'Success', {
                duration: 2000,
            });

            if (printSend.sendToClient === true) {
                this.data.requestCommunicationStatus = 'SentToClient';
            }

        },
            error => {
                this.snackBar.open('Could not send lab results', 'Error', {
                    duration: 2000,
                });
            });


    }

    downloadXMLWorklist() {
       this.requestService.getXMLWorklist(this.model.branch.id).subscribe(blob => {
        FileSaver.saveAs(blob, this.model.branch.name + '_worklist.xml');
    });
    }
}




