import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { LineItemInputService } from '../../../line-item-entry/services/line-item-input.service';
import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';
import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';
import { SearchService } from '../search-service';
import { VitalService } from '../../../configuration-management/services/vital.service';
import { DailyCareService } from '../../../configuration-management/services/daily-care.service';
import { LabResultService } from '../../../configuration-management/services/lab-results.service';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss']
})

export class SearchItemsComponent implements OnInit {

  @Input() lineItem: LineItem;
  @Input() item: any;
  @Input() locationId: string;
  @Input() itemType: string;
  @Output() selectedLineItem = new EventEmitter<any>();
  @Input() autoSearchItem: string;
  @Input() itemName: string;
  @Input() disabled: boolean;
  @Input() hideItems: boolean;
  @Input() placeholder: string;


  lineItemSearch = new FormControl();
  lineItemResults: LineItem[] = [];

  constructor(
    public lineItemInputService: LineItemInputService,
    public searchService: SearchService,
    public vitalsService: VitalService,
    public dailyCareService: DailyCareService,
    public labResultService: LabResultService
  ) { }

  ngOnInit() {

    if (this.item) {

      this.lineItemSearch = new FormControl(this.item.name);
    }

    if (this.itemName) {
      this.lineItemSearch = new FormControl(this.itemName);
    }

    if (this.lineItem) {
      this.lineItemSearch = new FormControl(this.lineItem.name);

    }

    if (this.autoSearchItem && !this.item && !this.itemName && !this.lineItem) {
      this.lineItemSearch = new FormControl(this.autoSearchItem);
      this.searchLineItems(this.autoSearchItem);
    }
    if (this.disabled) {
      this.lineItemSearch.disable();
    }

    this.lineItemSearch.valueChanges
    .pipe(
      debounceTime(300)
    )
    .subscribe(data => {
      this.searchLineItems(this.lineItemSearch.value);
    });
  }

  ngOnChanges(){
    let noInputParamsPassed = (this.autoSearchItem === null || this.autoSearchItem === undefined)
      && (this.item === null || this.item === undefined)
      && (this.itemName === null || this.itemName === undefined)
      && (this.lineItem === null || this.lineItem === undefined);

    if (noInputParamsPassed) {
      this.lineItemSearch.setValue(null);
    }
  }

  searchLineItems(lineItemSearch) {
    if ( lineItemSearch === '' ) return;
    switch (this.itemType) {
      case 'Vitals':
        this.searchVitals(lineItemSearch);
        break;
      case 'VitalGroups':
        this.searchVitalGroups(lineItemSearch);
        break;
      case 'DailyCare':
        this.searchDailyCare(lineItemSearch);
        break;
      case 'IVFluids':
        this.searchFluids(lineItemSearch);
        break;
      case 'LabResults':
        this.searchLabResults(lineItemSearch);
        break;
      case 'LabWork':
        console.log('test')
        this.searchLabs(lineItemSearch);
        break;
      case 'Medications':
        this.searchMedicationsAndSupplies(lineItemSearch);
        break;
      case 'Services':
        this.searchServiceCodes(lineItemSearch);
        break;

      default:
        break;
    }
  }

  searchVitals(lineItemSearch) {

    this.searchService.searchVitals(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchVitalGroups(lineItemSearch) {

    this.searchService.searchVitalGroups(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchDailyCare(lineItemSearch) {

    this.searchService.searchDailyCare(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchFluids(lineItemSearch) {

    this.searchService.searchFluids(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchLabResults(lineItemSearch) {

    this.searchService.searchLabResults(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }
  searchLabs(lineItemSearch) {

    this.searchService.searchLabs(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchServiceCodes(lineItemSearch) {
    this.searchService.searchServiceCodes(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  searchMedicationsAndSupplies(lineItemSearch) {
    this.searchService.searchMedicationsAndSupplies(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = this.hideItems ? response.filter(li => li.hideNonBillingCode == false) : response;
        }
      }
    );
  }

  searchGroups(lineItemSearch) {
    this.searchService.searchGroups(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }



  selectLineItem(lineItem: LineItem) {
    this.lineItemSearch.setValue('(' + lineItem.code + ') - ' + lineItem.name);
    this.lineItem = lineItem;

    switch (this.itemType) {
      case 'Vitals':
        this.getVital(lineItem);
        break;
        case 'VitalGroups':
          this.getVitalGroup(lineItem);
          break;
      case 'DailyCare':
        this.getDailyCare(lineItem);
        break;
      case 'LabResults':
        this.getLabResult(lineItem);
        break;
      default:
        this.getLineItem(lineItem);
        break;
    }


  }

  getVital(lineItem: LineItem) {
    this.vitalsService.getItem(lineItem.id).subscribe(response => {
      this.selectedLineItem.emit(response);
    });
  }

  getVitalGroup(lineItem: LineItem) {
    this.vitalsService.getVitalGroup(lineItem.id).subscribe(response => {
      this.selectedLineItem.emit(response);
    });
  }

  getDailyCare(lineItem: LineItem) {
    this.dailyCareService.getItem(lineItem.id).subscribe(response => {
      this.selectedLineItem.emit(response);
    });
  }

  getLabResult(lineItem: LineItem) {
    this.labResultService.getItem(lineItem.id).subscribe(response => {
      this.selectedLineItem.emit(response);
    });
  }

  getLineItem(lineItem: LineItem) {
    this.lineItemInputService.getLineItem(lineItem.id, lineItem.category).subscribe(response => {
      lineItem.totalPrice = response.totalPrice;
      lineItem.discountName = response.discountName;
      lineItem.pricePerUnit = response.pricePerUnit;
      lineItem.totalPrice = response.totalPrice;
      lineItem.quantity = response.quantity;
      lineItem.name = response.name;
      lineItem.itemId = response.id;
      lineItem.lineItemCategory = response.lineItemCategory;
      lineItem.approved = false;
      lineItem.location = response.location;
      lineItem.approvedUserId = '';
      lineItem.comments = '';
      lineItem.discount = new DiscountSummary();
      this.lineItem = lineItem;

      this.selectedLineItem.emit(lineItem);
    });
  }

}
