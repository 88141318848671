import Dexie from 'dexie';
import { LineItem, Drafts } from '../../line-item-entry/models/line-item.interface';
import { LocationSummary } from 'src/app/configuration-management/models/locations.interface';
import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { DiscountSummary } from '../../configuration-management/models/discounts.interface';
import { Client } from '../../client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { EmailViewModel } from 'src/app/configuration-management/models/email.interface';
import { ClientEmailDraft } from '../../configuration-management/models/email.interface';

export class AppDatabase extends Dexie {

    // medicationsSupplies: Dexie.Table<MedicationsSuppliesSummary, string>;

    lineItems: Dexie.Table<LineItem, string>;
    enabledLineItems: LineItem[];
    inventoryLocations: Dexie.Table<LocationSummary, string>;
    doctors: Dexie.Table<UserAccount, string>;
    discounts: Dexie.Table<DiscountSummary, string>;
    clientSearch: Dexie.Table<Client, string>;
    drafts: Dexie.Table<Drafts, string>;
    patients: Dexie.Table<Patient, string>;
    emailTemplates: Dexie.Table<EmailViewModel, string>;
    emailDraft: Dexie.Table<ClientEmailDraft, string>;

    constructor() {

        super('LoomisBasinDatabase');

        const dbContext = this;

        //
        // Define tables and indexes
        //
        dbContext.version(24).stores({
            lineItems: `
            id,
            discountId,
            discountName,
            totalPrice,
            quantity,
            pricePerUnit,
            lineItemCategory,
            name,
            nameNoCode,
            conversionFactor,
            hasAttachment,
            isControlledSubstance,
            canHide,
            lastUserToPrint,
            lastDateToPrint,
            isPrinted,
            causesDeath,
            groupItems,
            unitMultiplier,
            unit,
            disabled,
            needsRequest,
            hideNonBillingCode`,
            inventoryLocations: `
            id,
            addressSummary,
            branchId,
            branchName,
            contact,
            dateCreated,
            dateUpdated,
            description,
            disabled,
            hasInventory,
            isBarn,
            name`,
            // Doctors uses a compound key since it's sliced from one user
            doctors: `[id+doctorCode],
            name,
            departmentName,
            disabled,
            userQualifications,
            firstName,
            lastName,
            userName,
            dateCreated,
            dateUpdated,
            senderEmail,
            mobileNumber,
            emailConfirmed,
            email,
            id,
            role,
            userNumber,
            isVet,
            doctorCode,
            defaultLocationId,
            showOnSchedule`,
            discounts: `
            id,
            dateCreated,
            dateUpdated,
            decimalPercentage,
            description,
            disabled,
            discountPercentage,
            isExpired,
            name,
            percentage`,
            clientSearch: `
            id,
            name,
            primaryPhone,
            clientStatus,
            secondaryPhone,
            email,
            isEQProtect,
            accountBalance,
            billingStreetAddress,
            physicalStreetAddress,
            currentStatementId,
            clientId,
            createdDate,
            patients,
            billingType`,
            drafts: `
            id,
            medicalRecordDraft,
            lineItemDraft,
            communicationDraft,
            date,
            invoiceStatus`,
            patients: `
            id,
            name,
            patientNumber,
            ageYears,
            dateOfBirth,
            description,
            patientStatus,
            microchip,
            isAdmited,
            admittedDate,
            location,
            color,
            insuranceProvider,
            insurancePatientNumber,
            insurancePhoneNumber,
            species,
            breed,
            patientGender,
            patientSpecies,
            currentWhiteboardId,
            notes,
            eqProtectEnrollment,
            medicalNotes,
            overviewReminders
            `,
            emailTemplates: `
            id,
            htmlBody,
            emailType,
            description,
            isReminder
            `,
            emailDraft: `
            patientId,
            medicalRecord
            `
        });

        // Let's physically map Contact class to contacts table.
        // This will make it possible to call loadEmailsAndPhones()
        // directly on retrieved database objects.
        dbContext.lineItems.mapToClass(LineItem);
        this.updateEnabledLineItems();

        dbContext.inventoryLocations.mapToClass(LocationSummary);

        dbContext.doctors.mapToClass(UserAccount);

        dbContext.discounts.mapToClass(DiscountSummary);

        dbContext.clientSearch.mapToClass(Client);

        dbContext.drafts.mapToClass(Drafts);

        dbContext.patients.mapToClass(Patient);

        dbContext.emailTemplates.mapToClass(EmailViewModel);

        dbContext.emailDraft.mapToClass(ClientEmailDraft);
    }

    private updateEnabledLineItems() {
      this.lineItems.where('disabled').equals(0).toArray().then(  result =>
        this.enabledLineItems = result
      );
    }

    public bulkAddEmailTemplates(emailTemplates: EmailViewModel[]) {
        return dbContext.transaction('rw', dbContext.emailTemplates, async () => {

            await dbContext.emailTemplates.bulkPut(emailTemplates);
        });
    }

    public bulkAddPatients(patients: Patient[]) {
        return dbContext.transaction('rw', dbContext.patients, async () => {

            await dbContext.patients.bulkPut(patients);
        });
    }

    public bulkAddLineItems(lineItems: LineItem[]) {
        return dbContext.transaction('rw', dbContext.lineItems, async () => {

            await dbContext.lineItems.bulkPut(lineItems);
            this.updateEnabledLineItems();
        });
    }

    public bulkAddInventoryLocations(inventoryLocations: LocationSummary[]) {
        return dbContext.transaction('rw', dbContext.inventoryLocations, async () => {

            await dbContext.inventoryLocations.bulkPut(inventoryLocations);
        });
    }

    public bulkAddDoctors(doctors: UserAccount[]) {
        return dbContext.transaction('rw', dbContext.doctors, async () => {

            await dbContext.doctors.bulkPut(doctors);
        });
    }

    public bulkAddDiscounts(discounts: DiscountSummary[]) {
        return dbContext.transaction('rw', dbContext.discounts, async () => {

            await dbContext.discounts.bulkPut(discounts);
        });
    }

    public bulkAddClientsAndPatients(clients: Client[]) {
        return dbContext.transaction('rw', dbContext.clientSearch, async () => {

            await dbContext.clientSearch.bulkPut(clients);
        });
    }

    // Drafts CRUD
    public addDraft(draft: Drafts) {
        return dbContext.transaction('rw', dbContext.drafts, async () => {

            await dbContext.drafts.put(draft);
        });
    }

    public addEmailDraft(draft: ClientEmailDraft) {
        return dbContext.transaction('rw', dbContext.emailDraft, async () => {

            await dbContext.emailDraft.put(draft);
        });
    }

}

export let dbContext = new AppDatabase();
