import { DailyCare } from './daily-care.interface';

export class Hospitalization {
  id: string;
  name: string;
  defaultQuantity: string;
  minimumQuantity: string;
  price: string;
  cost: number;
  code: string;
  disabled: boolean;
  dailyCares: DailyCare[];
}

export class HospitalizationDailyCare {
  id: string;
  hospitalization: Hospitalization;
  hospitalizationId: string;
  dailyCare: DailyCare;
  dailyCareId: string;
  disabled: boolean;
}
