import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ReproductiveLog } from './patient-reproductive-log.model';
import { PrintSend } from '../send-print-button/send-print.model';

@Injectable({
  providedIn: 'root',
})
export class PatientReproductiveLogService extends BaseService {

  public delete(recordId: string): Observable<ReproductiveLog> {


    const route = `${this.apiUrl}/PatientRepro/${recordId}/Delete`;

    return this.http.post<ReproductiveLog>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public printSendPatientRepro(model: PrintSend): any {

    const route = `${this.apiUrl}/PatientRepro/${model.patientId}/PrintOrSend`;

    return this.http.post(route, model, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  public getRecord(recordId: string): Observable<ReproductiveLog> {


    const route = `${this.apiUrl}/PatientRepro/${recordId}`;

    return this.http.get<ReproductiveLog>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(reproductiveLog: ReproductiveLog, patientId: string): Observable<ReproductiveLog> {

    const body = reproductiveLog;
    const route = `${this.apiUrl}/PatientRepro/${patientId}/Update`;

    return this.http.post<ReproductiveLog>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
    }

  public create(reproductiveLog: ReproductiveLog, patientId: string): Observable<ReproductiveLog> {

    const body = JSON.stringify(reproductiveLog);
    const route = `${this.apiUrl}/PatientRepro/${patientId}/Create`;

    return this.http.post<ReproductiveLog>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
