import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VitalService } from '../../configuration-management/services/vital.service';
import { VitalGroup, Order } from '../../whiteboard/models/order.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { PatientVitalGroup } from './patient-vital-group.model';
import { PatientVitalGroupService } from './patient-vital-group.service';
import moment from 'moment';
import { Treatment } from '../../whiteboard/models/treatment.interface';
import { Whiteboard } from '../../whiteboard/models/whiteboard.interface';

export interface PatientVitalGroupDialogData {
  vitalGroup: VitalGroup;
  patient: Patient;
  patientVitalGroup: PatientVitalGroup;
  order: Order;
  hour: string;
  currentDay: string;
  orderType: string;
  whiteboard: Whiteboard;
  treatment: Treatment;
}

@Component({
  selector: 'app-patient-vital-group-dialog',
  templateUrl: './patient-vital-group.dialog.html',
  styleUrls: ['./patient-vital-group.dialog.scss']
})
export class PatientVitalGroupDialogComponent implements OnInit {

  vitalGroups: VitalGroup[];
  selectedGroup: VitalGroup;
  treatmentNotes: string;
  vitalGroupForm = new FormGroup({
    date: new FormControl(new Date()),
    id: new FormControl(null),
    patientVitals: new FormArray([])
  });

  constructor(
    public dialogRef: MatDialogRef<PatientVitalGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientVitalGroupDialogData,
    public snackBar: MatSnackBar,
    public vitalService: VitalService,
    public patientVitalGroupService: PatientVitalGroupService

  ) { }

  updateForm() {
    let formArray = this.vitalGroupForm.get('vitals') as FormArray;
    formArray = new FormArray([]);
    this.selectedGroup.vitals.forEach(x => {

      const formGroup = new FormGroup({
        vitalId: new FormControl(x.id),
        value: new FormControl(''),
        id: new FormControl(null)
      });

      formArray.push(formGroup);

    });

    const parsedHour = moment(this.data.hour, ['h:mm A']);
    const administeredTime = moment(this.data.currentDay);
    administeredTime.hour(parsedHour.hour());
    administeredTime.minute(0);
    administeredTime.second(0);
    administeredTime.millisecond(0);
    administeredTime.utcOffset(0);

    if (this.data.hasOwnProperty('order')) {
      this.vitalGroupForm = new FormGroup({
        date: new FormControl({ value: administeredTime.toDate(), disabled: true }),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray
      });

    } else if (this.data.hasOwnProperty('treatment')) {
      this.data.vitalGroup = this.data.treatment.patientVitalGroup as unknown as VitalGroup;
      formArray = new FormArray([]);
      this.data.treatment.patientVitalGroup.patientVitals.forEach(x => {

        const formGroup = new FormGroup({
          vitalId: new FormControl(x.vitalId),
          value: new FormControl(x.value),
          id: new FormControl(x.id)
        });

        formArray.push(formGroup);

      });
      this.vitalGroupForm = new FormGroup({
        date: new FormControl({ value: administeredTime.toDate(), disabled: true }),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray,
        id: new FormControl(this.data.treatment.patientVitalGroup.id)
      });
    }
    else {
      this.vitalGroupForm = new FormGroup({
        date: new FormControl(new Date()),
        vitalGroupId: new FormControl(this.selectedGroup.id),
        patientVitals: formArray
      });
    }


  }

  ngOnInit() {


    this.getItems();
  }

  getItems() {
    this.vitalService.indexVitalGroup().subscribe(response => {
      this.vitalGroups = response;

      if (this.data.hasOwnProperty('order')) {
        this.selectedGroup = this.vitalGroups.find(x => x.id === this.data.order.vitalGroup.id);
        this.updateForm();
      } else if (this.data.hasOwnProperty('treatment')) {

        this.selectedGroup = this.vitalGroups.find(x => x.id === this.data.treatment.patientVitalGroup.vitalGroupId);
        this.updateForm();
      }
    });
  }

  update() {
    const model = this.vitalGroupForm.value as PatientVitalGroup;
    this.patientVitalGroupService.update(model, this.data.patient.id).subscribe(res => {
      this.data.patientVitalGroup = model;
      this.snackBar.open('Record has been updated', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  submit() {
    const model = this.vitalGroupForm.value as PatientVitalGroup;

    if (this.data.hasOwnProperty('order')) {
      model.whiteboardTreatment = new Treatment();
      const parsedHour = moment(this.data.hour, ['h:mm A']);
      const administeredTime = moment(this.data.currentDay);
      administeredTime.hour(parsedHour.hour());
      administeredTime.minute(0);
      administeredTime.second(0);
      administeredTime.millisecond(0);
      administeredTime.utcOffset(0);
      model.whiteboardTreatment.administeredTime = administeredTime.toDate();
      model.date = administeredTime.toDate();
      model.whiteboardTreatment.orderType = this.data.orderType;
      model.whiteboardTreatment.whiteboardOrderId = this.data.order.id;
      model.whiteboardTreatment.note = this.treatmentNotes;
      model.whiteboardTreatment.patientVital = null;
      model.whiteboardTreatment.patientLabResult = null;
      model.whiteboardTreatment.whiteboardId = this.data.whiteboard.id;
    }

    this.patientVitalGroupService.create(model, this.data.patient.id).subscribe(res => {
      this.snackBar.open('Record has been created', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }


  onNoClick() {
    this.dialogRef.close();
  }
}
