import { Component, OnInit } from '@angular/core';

/** @title Sidenav open & close behavior */
@Component({
  selector: 'app-landing-page',
  templateUrl: 'landing-page.html',
  styleUrls: ['landing-page.scss'],
})
export class LandingPageComponent implements OnInit {

  backgroundUrls = [
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/0.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/1.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/2.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/3.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/4.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/5.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/6.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/7.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/8.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/9.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/10.jpg") no-repeat center center fixed',
    'url("https://loomis-assets.s3-us-west-2.amazonaws.com/Backgrounds/11.jpg") no-repeat center center fixed',
  ];
  selectedBackground: string;
  events: string[] = [];
  opened = true;
  shouldRun = true;

  ngOnInit() {
    const randomItem = Math.floor(Math.random() * 12);
    this.selectedBackground = this.backgroundUrls[randomItem];
  }


}
