import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { SystemTag } from 'src/app/configuration-management/models/system-tag.interface';
import { TagService } from "../tag.service";

@Component({
  selector: "app-show-tags",
  templateUrl: "show-tags.component.html",
  styleUrls: ["show-tags.component.scss"],
})
export class ShowTagsComponent implements OnInit, OnChanges {
  @Input("clientId") clientId: string;
  @Input("patientId") patientId: string;

  clientTags: SystemTag[];
  patientTags: SystemTag[];
  currentDate: Date;

  constructor(public tagService: TagService) {
    this.currentDate = new Date();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.currentDate = new Date();
    if (this.clientId != null) {
      this.getClientTags();
    }
    if (this.patientId != null) {
      this.getPatientTags();
    }
  }

  getClientTags() {
    this.tagService.getClientTagsById(this.clientId).subscribe((res) => {
      this.clientTags = res.tags;

    });
  }

  getPatientTags() {
    this.tagService.getPatientTagsById(this.patientId).subscribe((res) => {
      this.patientTags = res.tags;
    });
  }

  isExpired(expirationDate: Date) {
    if (expirationDate == null) {
      return false;
    } else if (new Date(expirationDate) <= this.currentDate) {
      return true;
    } else {
      return false;
    }
  }
}
