import { Stall } from './stall.interface';


export class LocationSummary {
  id: string;
  name: string;
  locationName: string;
  branchName: string;
  branchId: string;
  description: string;
  dateCreated: Date;
  dateUpdated: Date;
  isBarn: boolean;
  disabled: boolean;
  addressSummary: string;
  hasInventory: boolean;
  stalls: Stall[];
}

export class WhiteboardLocationFilter {
  id: string;
  name: string;
}
