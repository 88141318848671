import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OverviewReminder} from '../../../configuration-management/models/overview-reminder.interface';
import {UserService} from '../../../configuration-management/services/user.service';
import {EQProtectService} from '../../../patient-management/services/EQProtect.service';

@Component({
  selector: 'app-overview-reminder-preference-filter',
  templateUrl: './overview-reminder-preference-filter.component.html',
  styleUrls: ['./overview-reminder-preference-filter.component.css']
})
export class OverviewReminderPreferenceFilterComponent implements OnInit {

  availableOverviewReminders: OverviewReminder[] = [];
  storedOverviewReminders: OverviewReminder[] = [];
  selectedOverviewReminders: OverviewReminder[] = [];

  @Output() updatedPreference = new EventEmitter();

  constructor(
    public userService: UserService,
    public eqProtectService: EQProtectService,
  ) { }

  ngOnInit() {
    this.eqProtectService.getOverviewReminderList().subscribe(allOverviewReminders => {
      this.availableOverviewReminders = allOverviewReminders;
      this.userService.getOverviewReminderPreferences().subscribe(res => {
        this.selectedOverviewReminders = res.overviewReminders.map(item =>
          this.availableOverviewReminders.find(overviewReminder => overviewReminder.id === item.id));
        this.storedOverviewReminders = this.selectedOverviewReminders;
      });
    });
  }

  updatePreference() {
    this.userService.setOverviewReminderPreference(this.selectedOverviewReminders.map(o => o.id)).subscribe(res => {
      this.storedOverviewReminders = res.overviewReminders.map(item =>
        this.availableOverviewReminders.find(overviewReminder => overviewReminder.id === item.id));
      this.updatedPreference.emit();
    });
  }

}
