import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './helpers/auth.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    loadChildren: 'src/app/layout/layout.modules#LayoutModules'
  },
  {
    path: 'dashboard',
    loadChildren: 'src/app/dashboard/dashboard.modules#DashboardModules'
  },
  {
    path: 'client-management',
    loadChildren: 'src/app/client-management/client-management.modules#ClientManagementModules'
  },
  {
    path: 'patient-record',
    loadChildren: 'src/app/patient-management/patient-management.modules#PatientManagementModules'
  },
  {
    path: 'configuration-management',
    loadChildren: 'src/app/configuration-management/configuration-management.modules#ConfigurationManagementModules'
  },
  {
    path: 'schedule',
    loadChildren: 'src/app/schedule/schedule.modules#ScheduleModules'
  },
  {
    path: 'requests-results',
    loadChildren: 'src/app/requests-results/requests-results.modules#RequestsResultsModules'
  },
  {
    path: 'quick-entry',
    loadChildren: 'src/app/line-item-entry/line-item-entry.modules#LineItemEntryModules'
  },
  {
    path: 'whiteboard',
    loadChildren: 'src/app/whiteboard/whiteboard.modules#WhiteboardModules'
  },
  {
    path: 'inventory',
    loadChildren: 'src/app/inventory/inventory.modules#InventoryModules'
  },
  {
    path: 'reports',
    loadChildren: 'src/app/reports/reports.modules#ReportsModules'
  },
  {
    path: 'list-communications',
    loadChildren: 'src/app/list-communications/list-communications.modules#ListCommunicationsModules'
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
