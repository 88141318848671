import { Component, Inject, EventEmitter, Output, OnChanges, SimpleChanges, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/client-management/models/client.interface';
import { PrintSend } from './send-print.model';
import { SendToOtherDialogComponent } from '../send-to-other-dialog/send-to-other.dialog';




@Component({
    selector: 'app-send-print',
    templateUrl: 'send-print.component.html',
    styleUrls: ['send-print.component.scss']
})
export class SendPrintComponent {


    @Output() printSendOutput = new EventEmitter<PrintSend>();
    @Input() print: boolean;
    @Input() send: boolean;
    printSend = new PrintSend();
    
    constructor(public dialog: MatDialog) {
        
    }



    submit() {
        this.printSendOutput.emit(this.printSend);
        this.printSend.sendToClient = false;
        this.printSend.sendToDoctor = false;
        this.printSend.sendToOther = false;
        this.printSend.print = false;
        this.printSend.otherEmail = '';

    }

    sendToOther() {

        if (this.printSend.sendToOther === true) {
            return;
        }
        const dialogRef = this.dialog.open(SendToOtherDialogComponent, {
            width: '600px',
            data: 'Send Record to Other'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.printSend.otherEmail = result;
            }
         });
    }


}
