import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Inject, Component } from '@angular/core';

@Component({
  selector: 'app-prompt-install',
  templateUrl: 'prompt-install.html',
  styleUrls: ['prompt-install.scss'],
})
export class PromptComponent {

    constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
      private bottomSheetRef: MatBottomSheetRef<PromptComponent>
    ) {}
  
    public installPwa(): void {
      this.data.promptEvent.prompt();
      this.close();
    }
  
    public close() {
      this.bottomSheetRef.dismiss();
    }
  }