import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { HttpClient } from '@angular/common/http';
import { SyncService } from 'src/app/shared/offline/sync-service';
import { TagList } from './tag.model';
import { SystemTagList } from 'src/app/configuration-management/models/system-tag.interface';
import { ConfigLoaderService } from 'src/app/config-loader.service';

@Injectable({
  providedIn: 'root',
})
export class TagService extends BaseService {

  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService,
    public syncService: SyncService) {
    super(http, connectionService, configService);
  }



  public getClientTags(): Observable<TagList> {

    const route = `${this.apiUrl}/Tag/ClientTags`;

    return this.http.get<TagList>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getPatientTags(): Observable<TagList> {

    const route = `${this.apiUrl}/Tag/PatientTags`;

    return this.http.get<TagList>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getClientTagsById(clientId: string): Observable<SystemTagList> {
    const route = `${this.apiUrl}/Tag/GetClientTagsById/${clientId}`;

    return this.http.get<SystemTagList>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getPatientTagsById(patientId: string): Observable<SystemTagList> {
    const route = `${this.apiUrl}/Tag/GetPatientTagsById/${patientId}`;
    
    return this.http.get<SystemTagList>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
