import { Vital, LabResult } from './order.interface';
import { Whiteboard } from './whiteboard.interface';
import { PatientVitalGroup } from '../../shared/patient-vital-group-dialog/patient-vital-group.model';
import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';

export class Treatment {
  id: string;
  whiteboardOrderId: string;
  orderType: string;
  note: string = '';
  administeredTime: Date;
  patientVital: PatientVital;
  patientDailyCare: PatientVital;
  patientLabResult: PatientLabResult;
  patientLineItem: LineItem;
  patientId: string;
  treatmentStatus: string;
  lineItem: LineItem;
  patientVitalGroup: PatientVitalGroup;
  whiteboardId: string;
  userInitials: string;
}

export class PatientVital {
  vital: Vital;
  value: string;
  patientId: string;
  note: string;
  comments: string;
}

export class PatientLabResult {
  labResult: LabResult;
  value: string;
  note: string;
  patientId: string;
  date: Date;
  labResultId: string;
  lineItemId: string;
  id: string;
}
