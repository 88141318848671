import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../configuration-management/services/account.service';
import { User } from '../../configuration-management/models/user.interface';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { MatDialog } from '@angular/material/dialog';
import { SyncDialogComponent } from './sync-dialog/sync.dialog';
import { SyncService } from 'src/app/shared/offline/sync-service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  currentUser: User;
  events: string[] = [];
  opened = true;
  shouldRun = true;

  constructor(
    public accountService: AccountService,
    private router: Router,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public syncService: SyncService

  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);
   }

  ngOnInit() {

  }

  get routeToQuickEntry() {
    if (this.layoutService.quickEntry !== '' && this.layoutService.quickEntry !== undefined ) {
      return this.layoutService.quickEntry;
    } else {
      return '/quick-entry';
    }
  }

  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

  settings() {

    const dialogRef = this.dialog.open(SyncDialogComponent, {
      width: '50vw',
      height: '50vh'
    });

  }

}
