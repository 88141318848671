import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { LineItemInputService } from '../../../line-item-entry/services/line-item-input.service';
import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';
import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';
import { SearchService } from '../search-service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-line-items-no-groups',
  templateUrl: './search-line-items-no-groups.component.html',
  styleUrls: ['./search-line-items-no-groups.component.scss']
})

export class NoGroupsSearchLineItemsComponent implements OnInit {
  @Input() lineItem: LineItem;
  @Input() disabled: boolean;
  @Output() selectedLineItem = new EventEmitter<LineItem>();

  lineItemSearch = new FormControl({value: '', disabled: this.disabled});
  lineItemResults: Patient[] = [];

  constructor(
    public lineItemInputService: LineItemInputService,
    public searchService: SearchService
  ) { }

  ngOnInit() {
    if (this.lineItem) {
      this.lineItemSearch = new FormControl({value: this.lineItem.name, disabled: this.disabled});
    }

    this.lineItemSearch.valueChanges
    .pipe(
      debounceTime(300)
    )
    .subscribe(data => {
      this.searchlineItems(this.lineItemSearch.value);
    });
  }

  searchlineItems(lineItemSearch) {
    this.searchService.searchItemsNoGroups(lineItemSearch).subscribe(
      response => {
        if (response.length > 0) {
          this.lineItemResults = response;
        }
      }
    );
  }

  selectLineItem(lineItem: LineItem) {
    const currentLineItemName = '(' + lineItem.code + ') - ' + lineItem.name;
    this.lineItemSearch = new FormControl(currentLineItemName);
    this.lineItem = lineItem;


    this.lineItemInputService.getLineItem(lineItem.id, lineItem.category).subscribe(response => {
      lineItem.totalPrice = response.totalPrice;
      lineItem.discountName = response.discountName;
      lineItem.pricePerUnit = response.pricePerUnit;
      lineItem.totalPrice = response.totalPrice;
      lineItem.quantity = response.quantity;
      lineItem.name = response.name;
      lineItem.itemId = response.id;
      lineItem.lineItemCategory = response.lineItemCategory;
      lineItem.approved = false;
      lineItem.approvedUserId = '';
      lineItem.comments = '';
      lineItem.discount = new DiscountSummary();
      this.lineItem = lineItem;

      this.selectedLineItem.emit(lineItem);
    });
  }
}
