import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { SyncService } from 'src/app/shared/offline/sync-service';
import { BaseService } from 'src/app/helpers/base.service';
import {UserSummaryListViewModel, CreateUser, UserMetricsViewModel, PreferenceType} from '../models/user.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from 'src/app/client-management/models/client.interface';
import { SpeciesPreferenceViewModel } from '../models/species.interface';
import {OverviewReminderPreferenceViewModel} from '../models/overview-reminder.interface';
import { ConfigLoaderService } from 'src/app/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService,
    public syncService: SyncService) {
    super(http, connectionService, configService);
  }

  apiUsers: UserSummaryListViewModel;

  public get users() {
    if (this.apiUsers !== undefined) {
      return this.apiUsers;
    } else {
      // TODO: Throw error saying "NO CONNECTION"
      return;
    }
  }

  public getUser(id): Observable<CreateUser> {
    const route = `${this.apiUrl}/User/GetUser/${id}`;
    return this.http.get<CreateUser>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getDashboardMetrics(userId: string): Observable<UserMetricsViewModel> {
    const route = `${this.apiUrl}/User/${userId}/DashboardMetrics`;

    return this.http.get<UserMetricsViewModel>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }



  public getUsers(page: Page) {

      const route = `${this.apiUrl}/account/index`;

      const body = JSON.stringify({ page });
      this.http.post<UserSummaryListViewModel>(route, page, this.headers()).pipe(map(response => {
        return response;
      })).subscribe(x => {

        this.apiUsers = x;
      });

  }

  public createUser(createUserModel: CreateUser): Observable<any> {
    const route = `${this.apiUrl}/User/CreateUser`;
    const body = createUserModel;


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public updateUser(updateUserModel: CreateUser): Observable<any> {
    const route = `${this.apiUrl}/User/UpdateUser`;
    const body = updateUserModel;


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public deleteUser(userId: string): Observable<any> {
    const route = `${this.apiUrl}/User/DeleteUser/${userId}`;


    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getSpeciesPreferences(): Observable<SpeciesPreferenceViewModel> {
    const route = `${this.apiUrl}/User/GetPreferences/${PreferenceType.Species}`;

    return this.http.get<SpeciesPreferenceViewModel>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getOverviewReminderPreferences(): Observable<OverviewReminderPreferenceViewModel> {
    const route = `${this.apiUrl}/User/GetPreferences/${PreferenceType.OverviewReminder}`;

    return this.http.get<OverviewReminderPreferenceViewModel>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public setSpeciesPreference(speciesIds: string[]): Observable<SpeciesPreferenceViewModel> {
    const route = `${this.apiUrl}/User/SetSpeciesPreferences`;
    const body = JSON.stringify(speciesIds);

    return this.http.post<SpeciesPreferenceViewModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public setOverviewReminderPreference(overviewReminderIds: string[]): Observable<OverviewReminderPreferenceViewModel> {
    const route = `${this.apiUrl}/User/SetOverviewReminderPreferences`;
    const body = JSON.stringify(overviewReminderIds);

    return this.http.post<OverviewReminderPreferenceViewModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
