export class PrintSend {
    sendToDoctor: boolean = false;
    sendToClient: boolean = false;
    sendToOther: boolean = false;
    otherEmail: string = '';
    print: boolean = false;
    clientId: string;
    patientId: string;
    recordType: string;
    recordId: string;
}
