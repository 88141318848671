import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PatientVitalGroup } from './patient-vital-group.model';
import { PrintSend } from '../send-print-button/send-print.model';


@Injectable({
  providedIn: 'root',
})
export class PatientVitalGroupService extends BaseService {

  public delete(recordId: string): Observable<PatientVitalGroup> {


    const route = `${this.apiUrl}/PatientVitalGroup/${recordId}/Delete`;

    return this.http.post<PatientVitalGroup>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public printSendPatientVitals(model: PrintSend): any {

    const route = `${this.apiUrl}/PatientVitalGroup/${model.patientId}/PrintOrSend`;

    return this.http.post(route, model, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  public getRecord(recordId: string): Observable<PatientVitalGroup> {


    const route = `${this.apiUrl}/PatientVitalGroup/${recordId}`;

    return this.http.get<PatientVitalGroup>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(reproductiveLog: PatientVitalGroup, patientId: string): Observable<PatientVitalGroup> {

    const body = reproductiveLog;
    const route = `${this.apiUrl}/PatientVitalGroup/${patientId}/Update`;

    return this.http.post<PatientVitalGroup>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(patientVitalGroup: PatientVitalGroup, patientId: string): Observable<PatientVitalGroup> {

    const body = JSON.stringify(patientVitalGroup);
    const route = `${this.apiUrl}/PatientVitalGroup/${patientId}/Create`;

    return this.http.post<PatientVitalGroup>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
