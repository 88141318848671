import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';
import { Invoice } from 'src/app/line-item-entry/models/invoice.interface';

export class Statement {
  monthAndYear: Date;
  active: boolean;
  previousPrincipal: number;
  previousPrincipalDue: number;
  previousInterest: number;
  previousInterestDue: number;
  newInvoicesTotal: number;
  newInvoicesTotalDue: number;
  newPaymentsTotal: number;
  newPaymentsTotalRemaining: number;
  newInterestPayment: number;
  balanceDue: number;
  accountBalance: number;
  invoices: Invoice[];
  id: string;
  payments: Payment[];
}

export class ClientFinancialSummary {
  statement: Statement;
  payment: Payment;
  unfinishedBalance: number;
}

export class StatementClientEmail {
  id: string;
  name: string;
  email: string;
}

export class Payment {
  clientId: string;
  amount: number;
  paymentType: string;
  note: string;
  createdUser: string;
  datePaid: Date;
  invoiceId: string;
  invoiceNumber: number;
  number: number;
  clientName: string;
  userName: string;
  createdDate: string;
}

export class UpdateClosedInvoiceModel {
  invoiceId: string;
  newLineItems: LineItem[];
}
