import { Patient } from '../../patient-management/models/patient.interface';
import { ServiceCodeSummary } from './service-codes.interface';
import { LabSummary } from './labs.interface';
import { MedicationsSuppliesSummary } from './medicationsSupplies.interface';
import { Group } from './groups.interface';
export class OverviewReminder {
    patient: Patient;
    patientId: string;
    modifiedUser: string;
    name: string;
    showOnOverview: boolean;
    category: string;
    showOnEQProtect: boolean;
    occurrenceMonths: number;
    parentId: string;
    overviewReminderItems: OverviewReminderItem;
    id: string;
}

export class OverviewReminderItem{
    overviewReminderId: string;
    name: string;
    type: string;
    disabled: boolean;

    medicationsSuppliesId: string;
    labId: string;
    serviceCodeId: string;
    groupId: string;
    id: string;

}

export class OverviewReminderPreferenceViewModel {
  overviewReminders: OverviewReminderPreferenceItemViewModel[];
}

export class OverviewReminderPreferenceItemViewModel {
  id: string;
  name: string;
}
