import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import {
  Whiteboard,
  WhiteboardLocations,
  ChangeWBDoctors,
  WhiteboardFilters,
  WhiteboardFilterSources, WhiteboardIndex
} from '../models/whiteboard.interface';
import { Order, WhiteboardOrders } from '../models/order.interface';
import { Treatment } from '../models/treatment.interface';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class WhiteboardService extends BaseService {

  // Whiteboard

  public updateWhiteboard(model: Whiteboard): Observable<string> {
    const route = `${this.apiUrl}/whiteboard/${model.id}`;
    const body = JSON.stringify(model);
    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateWhiteboardDoctors(model: ChangeWBDoctors): Observable<any> {
    const route = `${this.apiUrl}/whiteboard/ChangeDoctors`;
    const body = JSON.stringify(model);
    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public createWhiteboard(createWhiteboardModel: Whiteboard, patientId: string): Observable<string> {
    const route = `${this.apiUrl}/whiteboard/CreateWhiteboard/${patientId}`;
    const body = JSON.stringify(createWhiteboardModel);
    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public dischargePatient(dateDismissed: Date, whiteboardId: string): Observable<any> {
    const route = `${this.apiUrl}/whiteboard/DischargePatient/${whiteboardId}`;
    const body = JSON.stringify(dateDismissed);
    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getCurrentWhiteboards(date: Date): Observable<Whiteboard[]> {

    const route = `${this.apiUrl}/Whiteboard/GetCurrentWhiteboards`;

    const body = JSON.stringify(date);

    return this.http.post<Whiteboard[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getCurrentFilteredWhiteboards(date: Date, doctor: string, location: string): Observable<WhiteboardIndex[]> {

    const route = `${this.apiUrl}/Whiteboard/GetCurrentFilteredWhiteboards`;

    var filters = new WhiteboardFilters();
    filters.date = date;
    filters.doctorFilterId = doctor;
    filters.locationFilterId = location;
    const body = JSON.stringify(filters);

    return this.http.post<WhiteboardIndex[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getCurrentWhiteboardFilters(): Observable<WhiteboardFilterSources> {

    const route = `${this.apiUrl}/Whiteboard/GetCurrentWhiteboardFilters`;

    return this.http.get<WhiteboardFilterSources>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getCurrentWhiteboardsNew(date: Date, doctor: string, location: string): Observable<WhiteboardIndex[]> {

    const route = `${this.apiUrl}/Whiteboard/GetCurrentWhiteboardsNew`;

    var filters = new WhiteboardFilters();
    filters.date = date;
    filters.doctorFilterId = doctor;
    filters.locationFilterId = location;
    const body = JSON.stringify(filters);

    return this.http.post<WhiteboardIndex[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getCurrentTreatments(currentDay: string): Observable<Treatment[]> {

    const route = `${this.apiUrl}/Whiteboard/GetCurrentTreatments?date=` + new Date(currentDay).toISOString();

    return this.http.get<Treatment[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getWhiteboard(whiteboardId: string, date: Date): Observable<Whiteboard> {



    const route = `${this.apiUrl}/Whiteboard/${whiteboardId}/GetWhiteboard`;
    const body = JSON.stringify(date);
    return this.http.post<Whiteboard>(route, body, this.headers()).pipe(map(response => {
      response.whiteboardTreatments.forEach(treatment => {
        treatment.administeredTime = moment(treatment.administeredTime).local().toDate();
      });
      return response;
    }));
  }

  public patientWhiteboards(patientId: string): Observable<Whiteboard[]> {



    const route = `${this.apiUrl}/Whiteboard/PatientWhiteboards/${patientId}`;
    return this.http.get<Whiteboard[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateWhiteboardNotes(whiteboardId: string, notes: string): Observable<string> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/UpdateWhiteboardNotes`;
    const body = JSON.stringify(notes);
    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  // Orders
  public createOrder(whiteboardId: string, model: Order): Observable<Order> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/CreateOrder`;
    model.endDate = new Date(model.endDate.toLocaleString());
    model.startDate = new Date(model.startDate.toLocaleString());
    const body = JSON.stringify(model);
    return this.http.post<Order>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateOrder(whiteboardId: string, model: Order): Observable<Order> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/UpdateOrder`;

    model.endDate = new Date(model.endDate.toLocaleString());
    model.startDate = new Date(model.startDate.toLocaleString());
    const body = JSON.stringify(model);
    return this.http.post<Order>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public stopOrder(whiteboardId: string, model: Order): Observable<string> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/StopOrder/${model.id}`;
    const body = JSON.stringify(model);
    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Treatments
  // Orders
  public applyTreatment(whiteboardId: string, model: Treatment): Observable<Treatment> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/CreateTreatment`;
    const body = JSON.stringify(model);

    return this.http.post<Treatment>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public incompleteTreatment(whiteboardId: string, model: Treatment): Observable<Treatment> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/IncompleteTreatment`;
    const body = JSON.stringify(model);
    return this.http.post<Treatment>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public updateTreatment(whiteboardId: string, model: Treatment): Observable<Treatment> {
    const route = `${this.apiUrl}/whiteboard/${whiteboardId}/UpdateTreatment`;
    const body = JSON.stringify(model);
    return this.http.post<Treatment>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Locations
  public whiteboardLocations(date: Date): Observable<WhiteboardLocations> {

    const route = `${this.apiUrl}/Whiteboard/WhiteboardLocations`;

    const body = JSON.stringify(date);

    return this.http.post<WhiteboardLocations>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public whiteboardStallStatus(stallId: string, stallStatus: string): Observable<any> {

    const route = `${this.apiUrl}/Whiteboard/WhiteboardLocations/SetStallStatus/${stallId}`;

    const body = JSON.stringify(stallStatus);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
