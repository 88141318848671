import { animate, state, style, transition, trigger } from '@angular/animations';
import {Component, OnInit, Inject, Input, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  OrphanHeskaTest,
  OrphanHeskaTests,
  OrphanPage,
  Request,
  RequestPageModel
} from '../../../models/request.interface';
import { RequestService } from '../../../services/request.service';
import {MatPaginator} from "@angular/material/paginator";
import {merge} from "rxjs";
import {map} from "rxjs/operators";

export class OrphanData {
    requestId: string;
}

@Component({
  selector: 'app-orphan-results-dialog',
  templateUrl: './orphan-results.dialog.html',
  styleUrls: ['./orphan-results.dialog.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrphanResultsDialogComponent implements OnInit {

    orphanTests: OrphanHeskaTest[];
    page: OrphanPage;

    dataSource = new MatTableDataSource<OrphanHeskaTest>();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    displayedColumns: string[] = [
        'testType',
        'testDate',
        'testSampleId',
        'testPatientId',
        'testSpecies',
        'actions',
    ];

    displayedColumnsExpanded: string[] = [
        'testName',
        'testValue',
        'testUnits',
    ];
    expandedElement: any | null;

    constructor(

        public dialogRef: MatDialogRef<OrphanResultsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrphanData,
        public snackBar: MatSnackBar,
        public requestService: RequestService,
    ) {

    }

    ngOnInit() {
      this.page = new OrphanPage();
      this.getOrphanTests();
    }

    ngAfterViewInit() {
      merge(this.paginator.page)
        .pipe(map(i => {
          this.page.pageNumber = this.paginator.pageIndex;
          this.page.size = this.paginator.pageSize;
          this.getOrphanTests();
          return this.paginator.pageIndex;
        })).subscribe(x => {  });
    }

  ngOnChanges() {
    this.getOrphanTests();
  }

    getOrphanTests() {
        this.requestService.getOrphanTasks(this.page.pageNumber, this.page.size).subscribe(res => {
            this.orphanTests = res.orphanTests;
            this.dataSource = new MatTableDataSource(this.orphanTests);
            this.page.totalElements = res.totalElements;
            this.paginator.length = res.totalElements;
            this.paginator.pageSize = this.page.size;
            this.paginator.pageIndex = this.page.pageNumber;
            // console.log("Fetched orphans", this.orphanTests)
            // console.log("Data source:", this.dataSource)
            // console.log("Total items:", res.totalElements)
        });
    }

    assignOrphanTest(model: OrphanHeskaTest) {
        this.requestService.assignOrphanHeskaTest(this.data.requestId, model.id).subscribe(res => {
            this.snackBar.open('Request was completed', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(this.data);
        });
    }

    hideOrphanTest(model: OrphanHeskaTest) {
      this.requestService.hideOrphanHeskaTest(model.id).subscribe(res => {
          this.snackBar.open('Orphan was hidden', 'Success', {
              duration: 2000,
          });
          this.dialogRef.close(this.data);
      });
  }
}




