import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { DailyCare } from '../models/daily-care.interface';

@Injectable({
  providedIn: 'root',
})
export class DailyCareService extends BaseService {

  public getItem(itemId: string): Observable<DailyCare> {
    const route = `${this.apiUrl}/DailyCare/${itemId}`;

    return this.http.get<DailyCare>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public index(): Observable<DailyCare[]> {
    const route = `${this.apiUrl}/DailyCare`;

    return this.http.get<DailyCare[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: DailyCare): Observable<DailyCare> {
    const route = `${this.apiUrl}/DailyCare/Create`;
    const body = JSON.stringify(model);

    return this.http.post<DailyCare>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: DailyCare): Observable<DailyCare> {
    const route = `${this.apiUrl}/DailyCare/Update`;
    const body = JSON.stringify(model);

    return this.http.post<DailyCare>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
