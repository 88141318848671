import { Component, OnInit, Input } from '@angular/core';
import { BILLINGTYPES } from 'src/app/client-management/models/client.interface';

@Component({
    selector: 'app-chips',
    templateUrl: 'chips.component.html',
    styleUrls: ['chips.component.scss']
})
export class ChipsComponent implements OnInit {

    billingTypes = BILLINGTYPES;
    @Input() billingType: string[];
    
    constructor(
    ) {

    }

    hasBillingType(type: string) {
        if (this.billingType) {
            return this.billingType.find(x => x === type);
        }
        return false;
    }


    ngOnInit() {

    }

}
