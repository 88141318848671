import { DoctorSchedule } from 'src/app/schedule/models/doctor-schedule.models';

export interface User {

  id: string;
  userName: string;
  firstName: string;
  senderEmail: string;
  lastName: string;
  token?: string;
  roles?: string[];
  calendarDoctors: string[];
  defaultLocationId: string;
  keepLoggedIn: boolean;
}

export class CreateUser {
  id: string;
  firstName: string;
  senderEmail: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  mobileNumber: string;
  role: string;
  isVet = false;
  showOnSchedule = false;
  defaultLocationId: string;
  mileRadius: number;
  showAmbulatoryAppointments: boolean;
  showHospitalVisits: boolean;
  appointmentTypes: string[];
  url: string;
  address: string;
  vicinity: string;
  placeId: string;
  order: number;
  departmentName: string;
  userQualifications: string;
  doctorCodes: string[] = [];
}

export interface CreateUserList {
  Users: CreateUser[];
}

export interface UserDialogData {
  id: string;
}

export class Page {
  size = 10;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  hasFilter = false;
  filter = '';
  requestType = '';
  requestorId = '';
  labId = null;
  clientId = '';
  patientId = '';
  testType = '';
  status = '';
  date: Date;
  search = '';
  preference = 'All';
  requestCommunicationStatus = 'All';
}

export interface UserSummary {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  dateCreated: string;
  dateUpdated: string;
  token?: string;
  roles?: string;
}
export interface UserMetricsViewModel {
  unapprovedLineItems: number;
  incompleteMedicalRecords: number;
  doctorProduction: number;
}

export interface UserSummaryListViewModel {
  users: Array<UserSummary>;
  page: Page;
}

export class UserAccount {
  id: string;
  name = '';
  firstName: string;
  lastName: string;
  email: string;
  roles: string;
  role: string;
  dateCreated: string;
  dateUpdated: string;
  userNumber: number;
  userSchedules: DoctorSchedule[];
  departmentName: string;
  userQualifications: string;
  doctorCode: string;
  disabled: boolean;
  calendarDoctors: string[];
  userName: string;
  senderEmail: string;
  mobileNumber: string;
  emailConfirmed: boolean;
  isVet: boolean;
  defaultLocationId: string;
  position: number;
  order: number;
}

export interface UserAccounts {
  accounts: UserAccount[];
}

export enum PreferenceType {
  Species = 'Species',
  OverviewReminder = 'OverviewReminder'
}
