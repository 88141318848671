import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Species } from '../models/species.interface';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService extends BaseService {
  public index(): Observable<Species[]> {
    const route = `${this.apiUrl}/Species`;

    return this.http.get<Species[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: Species): Observable<Species> {
    const route = `${this.apiUrl}/Species`;
    const body = JSON.stringify(model);

    return this.http.post<Species>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: Species): Observable<Species> {
    const route = `${this.apiUrl}/Species/Update`;
    const body = JSON.stringify(model);

    return this.http.post<Species>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateBreed(speciesId: string, newBreed:string,previousBreed:string): Observable<Species> {
    const route = `${this.apiUrl}/Species/UpdateBreed/${speciesId}`;
    const body = JSON.stringify({newBreed,previousBreed});

    return this.http.post<Species>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public addBreed(speciesId: string, breed:string): Observable<Species> {
    const route = `${this.apiUrl}/Species/AddBreed/${speciesId}`;
    const body = JSON.stringify(breed);

    return this.http.post<Species>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public removeBreed(speciesId: string, breed:string): Observable<Species> {
    const route = `${this.apiUrl}/Species/RemoveBreed/${speciesId}`;
    const body = JSON.stringify(breed);

    return this.http.post<Species>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
