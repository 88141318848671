import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Client, ClientSummaryListViewModel, Page, ClientFinancials } from '../models/client.interface';
import { HttpClient } from '@angular/common/http';
import { SyncService } from 'src/app/shared/offline/sync-service';
import { logging } from 'protractor';
import { Note } from 'src/app/shared/notes/note.model';
import { from } from 'rxjs';
import { Invoice } from 'src/app/line-item-entry/models/invoice.interface';
import { dbContext } from '../../shared/offline/offline-db.service';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { PatientMedicalRecord } from 'src/app/patient-management/models/patient.interface';
import { ConfigLoaderService } from 'src/app/config-loader.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {

  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService,
    public syncService: SyncService) {
    super(http, connectionService, configService);
  }

  clientSearch: Client[];
  apiClients: ClientSummaryListViewModel;
  apiClient: Client;

  public destroy() {
    this.apiClients = new ClientSummaryListViewModel();
  }
  public get clients() {

    if (this.apiClients !== undefined && this.isConnected) {
      return this.apiClients;
    } else if (this.syncService.clients !== undefined) {
      return this.syncService.clients;
    }
    return;

  }

  public get client() {

    if (this.apiClient !== undefined && this.isConnected) {
      return this.apiClient;
    } else if (this.syncService.client !== undefined) {
      return this.syncService.client;
    }
    return;
  }

  public clearClient() {
    this.apiClient = null;
    this.syncService.client = null;
  }

  public getClient(id) {


      const route = `${this.apiUrl}/client/${id}`;

      this.http.get<Client>(route, this.headers()).pipe(map(response => {

        return response;

      })).subscribe(x => {
        this.apiClient = x;
      });

  }

  public getClientById(id): Observable<Client> {


    const route = `${this.apiUrl}/client/${id}`;

    return this.http.get<Client>(route, this.headers()).pipe(map(response => {
      return response;
    }));



  }

  public getClients(page: Page) {


    const route = `${this.apiUrl}/client/index`;

    const body = JSON.stringify({ page });
    this.http.post<ClientSummaryListViewModel>(route, page, this.headers()).pipe(map(response => {

      return response;

    })).subscribe(x => {
      this.apiClients = x;
    });

  }

  public createClient(createClientModel: Client): Observable<any> {

    const route = `${this.apiUrl}/client`;
    const body = createClientModel;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public mergeClients(firstClient: string, secondClient: string): Observable<any> {

    const route = `${this.apiUrl}/client/${firstClient}/merge/${secondClient}`;

    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {

      return response;
    }));
  }


  public showClientRecords(records: PatientMedicalRecord[]): Observable<any> {

    const route = `${this.apiUrl}/client/ShowClientRecords`;

    let body = JSON.stringify(records);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public async searchClients() {


    if (this.clientSearch == null) {
      this.clientSearch = await dbContext.clientSearch.toArray();
    }

    return this.clientSearch;

  }

  public updatePrivateNotes(notes: Note[], clientId: string): Observable<Note[]> {


    const body = notes;
    const route = `${this.apiUrl}/Client/UpdateClientNotes/${clientId}`;

    return this.http.post<Note[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateClient(updateClientModel: Client): Observable<any> {

    const route = `${this.apiUrl}/client/update`;
    const body = updateClientModel;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public clientFinancials(clientId: string): Observable<ClientFinancials> {

    const route = `${this.apiUrl}/client/${clientId}/ClientFinancials`;

    return this.http.get<ClientFinancials>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public clientTransfers(clientId: string): Observable<ClientFinancials> {

    const route = `${this.apiUrl}/client/${clientId}/ClientTransfers`;

    return this.http.get<ClientFinancials>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getRecentInvoices(clientId: string): Observable<any[]> {

    const route = `${this.apiUrl}/client/${clientId}/GetRecentInvoices`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
