import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/helpers/base.service';
import { Observable } from 'rxjs';
import { RequestInvoices, Invoice, ClientInvoice } from '../models/invoice.interface';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InvoiceDraft } from '../models/invoice-draft.interface';
import { Payment } from 'src/app/list-communications/models/statement.interface';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {


  public getDashboardInvoices(patientId: string, clientId: string, userId: string, startDate: Date, endDate: Date, status: string, invoiceStatus: string, incompleteMedicalRecords: boolean): Observable<Invoice[]> {
    const route = `${this.apiUrl}/Invoice/GetDashboardInvoices`;
    const model = { patientId, clientId, userId, startDate, endDate, status, invoiceStatus, incompleteMedicalRecords } as RequestInvoices;
    const body = JSON.stringify(model);
    return this.http.post<Invoice[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public getInvoice(invoiceId: string): Observable<Invoice> {
    const route = `${this.apiUrl}/Invoice/GetInvoice/${invoiceId}`;
    return this.http.get<Invoice>(route, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public trackRevisions(invoiceId: string): Observable<any> {
    const route = `${this.apiUrl}/Invoice/${invoiceId}/TrackRevisions`;
    return this.http.get<Invoice>(route, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public setInvoiceStatus(invoiceId: string, invoiceStatus: string): Observable<any> {
    const route = `${this.apiUrl}/Invoice/${invoiceId}/SetStatus/${invoiceStatus}`;
    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public deleteInvoice(invoiceId: string): Observable<null> {
    const route = `${this.apiUrl}/Invoice/${invoiceId}/DeleteInvoice`;
    return this.http.post<null>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public mergeInvoices(firstInvoiceId: string, secondInvoiceId: string): Observable<null> {
    const route = `${this.apiUrl}/Invoice/${firstInvoiceId}/Merge/${secondInvoiceId}`;

    return this.http.post<null>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getPatientInvoices(clientId: string, patientId: string): Observable<Invoice[]> {

    const route = `${this.apiUrl}/Invoice/ClientInvoices/${clientId}/Patient/${patientId}`;

    return this.http.get<Invoice[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Auto save
  public autoSave(invoiceDraft: InvoiceDraft): Observable<InvoiceDraft> {
    const route = `${this.apiUrl}/Invoice/AutoSave`;
    const body = JSON.stringify(invoiceDraft);
    return this.http.post<InvoiceDraft>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public discardDraft(invoiceDraft: InvoiceDraft): Observable<InvoiceDraft> {
    const route = `${this.apiUrl}/Invoice/DiscardInvoices/${invoiceDraft.id}`;
    return this.http.post<InvoiceDraft>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public savedInvoices(userId: string): Observable<InvoiceDraft[]> {
    const route = `${this.apiUrl}/Invoice/SavedInvoices/${userId}`;
    return this.http.get<InvoiceDraft[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
