import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Branch } from '../models/branch.interface';

@Injectable({
  providedIn: 'root',
})
export class BranchService extends BaseService {

  public index(): Observable<Branch[]> {

    const route = `${this.apiUrl}/Branch`;

    return this.http.get<Branch[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: any): Observable<any> {

    const route = `${this.apiUrl}/Branch/Create`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: any): Observable<any> {

    const route = `${this.apiUrl}/Branch/Update`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getByName(name: string): Observable<any> {

    const route = `${this.apiUrl}/Branch/GetByName/${name}`;
    const body = JSON.stringify(name);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
