import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dbContext } from '../../offline/offline-db.service';
import { Client } from '../../../client-management/models/client.interface';
import { Router } from '@angular/router';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { OverviewReminder } from '../../../configuration-management/models/overview-reminder.interface';

@Component({
  selector: 'app-patient-view-dialog',
  templateUrl: './patient-view.dialog.html',
  styleUrls: ['./patient-view.dialog.scss']
})
export class PatientViewDialog implements OnInit {

  patient: Patient;

  constructor(
    public dialogRef: MatDialogRef<PatientViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public router: Router
  ) {
    this.getPatientById();
  }


  ngOnInit() {


  }

  getPatientById() {

    dbContext.patients.where('id').equals(this.data).toArray().then(x => {
      this.patient = x[0];


    });
  }

  route() {
    this.router.navigateByUrl(`/patient-record/${this.data}`);
    this.dialogRef.close();
  }

  getOverview(): OverviewReminder[] {
    if (this.patient.overviewReminders) {
      return this.patient.overviewReminders.filter(x => x.showOnEQProtect === false);
    } else {
      return [];
    }
  }

  getEqProtect(): OverviewReminder[] {
    if (this.patient.overviewReminders) {
      return this.patient.overviewReminders.filter(x => x.showOnEQProtect === true);
    } else {
      return [];
    }

  }


  onNoClick() {
    this.dialogRef.close();
  }
}
