export class DiscountSummary {
  id: string;
  name: string;
  description: string;
  isExpired: boolean;
  disabled: boolean;
  discountPercentage: string;
  decimalPercentage: number;
  dateCreated: Date;
  dateUpdated: Date;
  percentage: number;

}
