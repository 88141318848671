import { LocationSummary } from './locations.interface';

export class Branch {
  id: string;
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  addressSummary: string;
  disabled: boolean;
  locations: LocationSummary[];
  url: string;
  vicinity: string;
  placeId: string;
}
