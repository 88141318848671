import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCheckAllComponent {
// tslint:disable-next-line:max-line-length
// https://stackblitz.com/edit/select-all-option-angular-material?file=src%2Fapp%2Fcustom-material%2Fselect-check-all%2Fselect-check-all.component.css,src%2Fapp%2Fcustom-material%2Fselect-check-all%2Fselect-check-all.component.html,src%2Fapp%2Fcustom-material%2Fselect-check-all%2Fselect-check-all.component.ts

  @Input() selectedItems: any[] = [];
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Input() availableItems: any[] = [];
  @Input() text = 'Select All';

  isChecked(): boolean {
    return this.selectedItems.length > 0 && this.availableItems.length > 0
      && this.selectedItems.length === this.availableItems.length;
  }

  isIndeterminate(): boolean {
    return this.selectedItems.length > 0 && this.availableItems.length > 0
      && this.selectedItems.length < this.availableItems.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.selectedItems = this.availableItems;
    } else {
      this.selectedItems = [];
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
