import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { LocationService } from '../../../configuration-management/services/location.service';
import { LineItemLocation } from 'src/app/line-item-entry/models/line-item.interface';
import { LineItem } from '../../../line-item-entry/models/line-item.interface';
import { MatDialog } from '@angular/material/dialog';
import { ClientViewDialog } from '../../../shared/search/client-view-dialog/client-view.dialog';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    @Output() closeWindow = new EventEmitter<boolean>();
    constructor(
        private router: Router,
        public dialog: MatDialog
    ) {

    }

    routeClient(client: Client) {
        const dialogRef = this.dialog.open(ClientViewDialog, {
            width: '100vw',
            height: '99vh',
            data: client.id
        });


        this.close();
    }

    routePatient(patient: Patient) {
        this.router.navigateByUrl(`/patient-record/${patient.id}`);
        this.close();
    }

    routeLineItem(lineItem: LineItem) {
        if (lineItem.lineItemCategory === 'MedicationsAndSupplies') {
            this.router.navigateByUrl(`/inventory/item-details/${lineItem.id}`);
        }

        this.close();
    }

    ngOnInit() {

    }


    close() {
        this.closeWindow.emit(true);
    }

}
