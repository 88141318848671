import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Patient } from '../../patient-management/models/patient.interface';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { PatientReproductiveLogService } from './patient-reproductive-log.service';
import { ReproductiveLog } from './patient-reproductive-log.model';

export interface PatientReproductiveLogDialogData {
    reproductiveLog: ReproductiveLog;
    patient: Patient;
}

@Component({
    selector: 'app-patient-reproductive-log-dialog',
    templateUrl: './patient-reproductive-log.dialog.html',
    styleUrls: ['./patient-reproductive-log.dialog.scss']
})
export class PatientReproductiveLogDialogComponent implements OnInit {


    reproductiveLogForm = new FormGroup({
        comment: new FormControl(''),
        lo: new FormControl(''),
        ro: new FormControl(''),
        uterus: new FormControl(''),
        cervix: new FormControl(''),
        date: new FormControl(new Date()),
        id: new FormControl(null)
    });

    constructor(
        public dialogRef: MatDialogRef<PatientReproductiveLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PatientReproductiveLogDialogData,
        public snackBar: MatSnackBar,
        public patientReproductiveLogService: PatientReproductiveLogService

    ) { }


    ngOnInit() {
        if (this.data.reproductiveLog !== null) {
            this.reproductiveLogForm = new FormGroup({
                comment: new FormControl(this.data.reproductiveLog.comment),
                lo: new FormControl(this.data.reproductiveLog.lo),
                ro: new FormControl(this.data.reproductiveLog.ro),
                uterus: new FormControl(this.data.reproductiveLog.uterus),
                cervix: new FormControl(this.data.reproductiveLog.cervix),
                date: new FormControl(this.data.reproductiveLog.date),
                id: new FormControl(this.data.reproductiveLog.id)
            });
        }
    }

    update() {
        const model = this.reproductiveLogForm.value as ReproductiveLog;
        this.patientReproductiveLogService.update(model, this.data.patient.id).subscribe(res => {
            this.data.reproductiveLog = model;
            this.snackBar.open('Record has been updated', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(res);
        });
    }

    submit() {
        const model = this.reproductiveLogForm.value as ReproductiveLog;
        this.patientReproductiveLogService.create(model, this.data.patient.id).subscribe(res => {
            this.snackBar.open('Record has been created', 'Success', {
                duration: 2000,
            });
            this.dialogRef.close(res);
        });
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
