import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { LineItemInputService } from '../../../line-item-entry/services/line-item-input.service';
import { LineItem } from 'src/app/line-item-entry/models/line-item.interface';
import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';
import { SearchService } from '../search-service';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-search-line-items',
  templateUrl: './search-line-items.component.html',
  styleUrls: ['./search-line-items.component.scss']
})
export class SearchLineItemsComponent implements OnInit {

  @Input() lineItem: LineItem;
  @Input() locationId: string;
  @Input() disabled: boolean;
  @Output() selectedLineItem = new EventEmitter<LineItem>();

  lineItemSearch = new FormControl({ value: '', disabled: this.disabled });
  lineItemResults: LineItem[] = [];
  filteredOptions: Observable<LineItem[]>;


  constructor(
    public lineItemInputService: LineItemInputService,
    public searchService: SearchService
  ) { }

  ngOnInit() {
    if (this.lineItem) {
      this.lineItemSearch = new FormControl({ value: this.lineItem.name, disabled: this.disabled });
    }


    this.getLineItems();
  }

  getLineItems() {
    this.searchService.getLineItems().then(
      response => {

        this.lineItemResults = response;

        this.filteredOptions = this.lineItemSearch.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      }
    );
  }


  selectLineItem(lineItem: LineItem) {
    const currentLineItemName = lineItem.name;
    this.lineItemSearch = new FormControl(currentLineItemName);
    this.lineItem = lineItem;
    this.selectedLineItem.emit(lineItem);
  }

  private _filter(value: string): LineItem[] {

    if (value && typeof value === "string") {
      const filterValue = value.toLowerCase();
      return this.lineItemResults.filter(lineItem => lineItem.name.toLowerCase().includes(filterValue));
    }

  }
}
