import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ControlledSubstanceLogViewModel, ControlledSubstanceLogPage, ControlledSubstanceLog } from '../models/controlled-substance-logs.models';


@Injectable({
  providedIn: 'root',
})
export class ControlledSubstanceLogsService extends BaseService {


  public index(page: ControlledSubstanceLogPage): Observable<ControlledSubstanceLogViewModel> {
    const route = `${this.apiUrl}/ControlledSubstanceLog`;
    const body = JSON.stringify(page);
    return this.http.post<ControlledSubstanceLogViewModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: ControlledSubstanceLog, patientId: string): Observable<any> {
    const route = `${this.apiUrl}/ControlledSubstanceLog/${patientId}`;
    const body = JSON.stringify(model);
    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
