import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Vital, VitalGroup } from '../../whiteboard/models/order.interface';


@Injectable({
  providedIn: 'root',
})
export class VitalService extends BaseService {

  public getItem(itemId: string): Observable<Vital> {
    const route = `${this.apiUrl}/Vital/${itemId}`;

    return this.http.get<Vital>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public index(): Observable<Vital[]> {
    const route = `${this.apiUrl}/Vital`;

    return this.http.get<Vital[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: Vital): Observable<Vital> {
    const route = `${this.apiUrl}/Vital/Create`;
    const body = JSON.stringify(model);

    return this.http.post<Vital>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: Vital): Observable<Vital> {
    const route = `${this.apiUrl}/Vital/Update`;
    const body = JSON.stringify(model);

    return this.http.post<Vital>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Vital Groups
  public getVitalGroup(itemId: string): Observable<VitalGroup> {
    const route = `${this.apiUrl}/Vital/GetVitalGroup/${itemId}`;

    return this.http.get<VitalGroup>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public indexVitalGroup(): Observable<VitalGroup[]> {
    const route = `${this.apiUrl}/Vital/VitalGroup/VitalGroupIndex`;

    return this.http.get<VitalGroup[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public createVitalGroup(model: VitalGroup): Observable<VitalGroup> {
    const route = `${this.apiUrl}/Vital/VitalGroup/Create`;
    const body = JSON.stringify(model);

    return this.http.post<VitalGroup>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateVitalGroup(model: VitalGroup): Observable<VitalGroup> {
    const route = `${this.apiUrl}/Vital/VitalGroup/Update`;
    const body = JSON.stringify(model);

    return this.http.post<VitalGroup>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public addVitalGroupVital(vitalGroupId: string, vitalId: string): Observable<VitalGroup> {
    const route = `${this.apiUrl}/Vital/VitalGroup/${vitalGroupId}/AddVital/${vitalId}`;

    return this.http.post<VitalGroup>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public removeVitalGroupVital(vitalGroupId: string, vitalId: string): Observable<VitalGroup> {
    const route = `${this.apiUrl}/Vital/VitalGroup/${vitalGroupId}/DisableVital/${vitalId}`;

    return this.http.post<VitalGroup>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}

