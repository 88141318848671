import { AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { MatAutocomplete, MatOption, MatSelect } from '@angular/material';

// this pattern was taken from
// https://benjamin-maisonneuve1.medium.com/angular-material-embed-matoption-inside-a-add2b6ec3ee
// https://github.com/BobBDE/material-select-option-embedded/tree/master/src/app/embedded-options-final
export abstract class AbstractEmbeddedMatOptionsComponent implements AfterViewInit {

  @ViewChildren(MatOption) protected options: QueryList<MatOption>;

  protected constructor(private optionParent: MatSelect | MatAutocomplete) {
    if (optionParent == null) {
      console.error('The component must be a child of a mat-select or a mat-autocomplete');
    }
  }

  /**
   * Implement this method to call the initOptions method
   *
   * This method must call the initOptions
   */
  abstract ngAfterViewInit(): void;

  /**
   * Call the init method to init the options
   * This method must be called on the AfterViewInit life cycle
   *
   * This method must be call only Once
   */
  protected initOptions(): void {
    // the observable is complete on destroy
    this.options.changes.subscribe(
      options => this.registerSelectOptions(this.optionParent, options),
    );
    this.registerSelectOptions(this.optionParent, this.options);
  }

  /**
   * This method can be called to manually register options
   * @param select MatSelect instance
   * @param options options to add
   */
  protected registerSelectOptions(select: MatSelect | MatAutocomplete, options: QueryList<MatOption>): void {
    select.options.reset([
        ...select.options.toArray(),
        ...options.toArray()
      ]
    );
    select.options.notifyOnChanges();
  }
}
