import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';
import { UserAccount } from '../../configuration-management/models/user.interface';
import { Patient } from '../../patient-management/models/patient.interface';
import { PatientLabResult } from '../../whiteboard/models/treatment.interface';
import { EmailViewModel } from 'src/app/configuration-management/models/email.interface';
import { CreateCommunication } from 'src/app/client-management/models/communication.interface';
import { Client } from '../../client-management/models/client.interface';
import { Request } from 'src/app/requests-results/models/request.interface';

export class LineItem {
  position: number;
  patient: Patient;
  discountName:string = '';
  discountId: string;
  totalPrice: number;
  quantity:number = 1;
  pricePerUnit: number = 0;
  lineItemCategory:string = '';
  category:string = '';
  canHide: boolean;
  hideFromInvoice: boolean;
  name:string = '';
  code:string = '';
  itemId:string = '';
  hasAttachment: boolean;
  conversionFactor:string = '';
  date: Date;
  comments:string = '';
  id: string;
  hideNonBillingCode: boolean = false;
  location: LineItemLocation;
  approved:boolean = false;
  groupItems: LineItem[];
  approvedUserId: string;
  overrideDescription: string = '';
  doctorCode: string;
  assistingDoctorCode: string;
  doctor:UserAccount = new UserAccount();
  asstDoctor:UserAccount = new UserAccount();
  discount:DiscountSummary = new DiscountSummary();
  dateOfWork: Date;
  hasMedicalNote: boolean;
  lastUserToPrint: string;
  lastDateToPrint: string;
  isPrinted:boolean = false;
  needsRequest:boolean = false;
  instructions: string;
  nameNoCode: string;
  request: Request;
  requestId: string;
  patientLabResults: PatientLabResult[];
  isControlledSubstance: boolean;
  disabled: number;
  causesDeath: boolean;
  email: EmailViewModel;
  lineItemRequest: LineItemRequest;
  labRequestTemporaryS3FileId: string;
}

// Refill Data is for Medication Pickup Requests
export class LineItemRequest {
  id: string;
  refillStatus: string;
  refillDate: Date;
  refillStatusUserId: string;
  refillStatusDate: Date;
  note: string;
}

export class LineItemLocation {
  branchName: string;
  branchId: string;
  addressSummary: string;
  contact: string;
  id: string;
}

export class LineItemSubmission {
  patientId = '';
  clientId = '';
  lineItems: LineItem[];
  selectedClient: Client;
  selectedPatient: Patient;
  invoiceStatus: string;
}

export class Drafts {
  id: string;
  date: Date;
  medicalRecordDraft:EmailViewModel = new EmailViewModel();
  lineItemDraft: LineItemSubmission;
  communicationDraft:EmailViewModel = new EmailViewModel();
}



