import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import 'hammerjs';
import { ConfigLoaderService } from "./app/config-loader.service";

const environment = (configService: ConfigLoaderService) => () => configService.config;

if (!environment['dev']) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // waiting for angular to load bootstrapping and then only loading service worker
  .then(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("ngsw-worker.js");
    }
  })
  .catch((err) => console.log(err));
